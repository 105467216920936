import { Avatar, Button, Grid, IconButton } from "@mui/material"

export const HintPallette = ({currentHint, hints, setCurrentHint}) => {
    const buttonColor = (index) => {
        if (index === currentHint) return "#4545A5"
        else return "#FFFFFF"
    }

    return (
        <Grid item={true} container md={12} lg={12} mb={2} sx={{ display: { md: 'none', lg: 'flex', margin: 2.5, } }}>
            {
                hints?.map((hint, index) => {
                    return <Grid key={index} item={true} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <IconButton onClick={() => {
                            setCurrentHint(index);
                        }}
                            ><Avatar sx={{ width: '32px', height: '32px', color: index === currentHint ? '#FFFFFF' : '#1E232C', backgroundColor: buttonColor(index), border: '0.25px solid #1C1B1F', fontSize: '14px' }}>{index+1}</Avatar>
                        </IconButton>
                    </Grid>
                })
            }
        </Grid>
    )
}