import { useState, useEffect, useRef } from "react";
import { TestStartLayout } from "../../designs/Test/TestStartLayout";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import useTests from "../../hooks/useTests";
import { useStudent } from "../../hooks/useStudent";
import { useStore } from "../../stores";
import { useNavigate } from "react-router-dom";
import { environment } from "../../environments/environment";
import jwtDecode from "jwt-decode";
import { PracticeStartLayout } from "../../designs/Test/PracticeStartLayout";
import { usePractice } from "../../hooks/usePractice";
import { CircularProgress } from "@mui/material";
import { useActivity } from "../../hooks/useActivity";

export const SessionStart = ({ mobile }) => {
	const navigate = useNavigate();
	const navigateBack = () => navigate("/dashboard");
	const params = useParams();

	const currentUser = useStore((state) => state.currentUser);
	const clearCurrentUser = useStore((state) => state.clearCurrentUser);
	const setCurrentUser = useStore((state) => state.setCurrentUser);
	const [currentSession, setCurrentSession] = useState({});
	const [pageData] = useState({});
	const [packageData, setPackageData] = useState({});
	const [testStatus, setTestStatus] = useState("start");
	const [timeLeft, setTimeLeft] = useState(1800);
	const [sessionDataRecieved, setSessionDataRecieved] = useState(false);
	const [sessionCreated, setSessionCreated] = useState(false);
	const [numQuestions, setNumQuestions] = useState(0);
	const [questionsLeft, setQuestionsLeft] = useState(0);
	const [review, setReview] = useState(false);
	const [studentsList, setStudentsList] = useState();
	const [recommendation, setRecommendation] = useState({});
	const [series, setSeries] = useState("n/a");

	const { getPackageById, createTest, getSessions, reviewSessionsByPackageId } =
		useTests();
	const { getProfile, getUserStudents } = useStudent();
	const { createPracticeSession, creatMockSession } = usePractice();
	const student = JSON.parse(localStorage.getItem("current_student"));
	let packageId = params.packageId;
	let authToken = currentUser?.access_token ?? params.userToken;
	let studIndex = student?.studIndex ?? params.studIndex;
	const session = JSON.parse(sessionStorage.getItem("session"));
	const [webcamEnabled, setWebcamEnabled] = useState(false);

	const webcamRef = useRef(null);

	const { data: StudentListData } = useQuery(
		["StudentList"],
		() => getUserStudents(jwtDecode(authToken).userId),
		{ enabled: true, retry: false }
	);

	const { createActivity } = useActivity();

	const { data: sessions } = useQuery([`Sessions`, studIndex], () =>
		getSessions(student?.studIndex)
	);

	useEffect(() => {
		if (sessions?.data?.length > 0) {
			sessions?.data?.forEach((session) => {
				if (session?.packageId === packageId) {
					if (session?.timeLeft === 0) {
						setTimeLeft(0);
						setTestStatus("review");
					} else {
						setTimeLeft(session?.timeLeft);
						setTestStatus("resume");
					}
				}
			});
		}
	}, [sessions, packageId]);

	const { data: PackageDetails } = useQuery(
		["PackageDetails"],
		() => getPackageById(packageId),
		{ enabled: true, retry: false }
	);

	const createTestSession = async () => {
		if (packageData) {
			if (series === "Practice" || series === "Screening") {
				return await createPracticeSession(
					packageId,
					{
						subjects: packageData?.data?.subject,
						grades: packageData?.data?.grade,
						series: packageData?.data?.series,
						studentIndex: studIndex,
					},
					authToken
				);
			} else {
				return await creatMockSession(
					packageId,
					{
						subjects: packageData?.data?.subject,
						grades: packageData?.data?.grade,
						series: packageData?.data?.series,
						studentIndex: studIndex,
						difficulty: [],
					},
					authToken
				);
			}
		}
	};

	useEffect(() => {
		console.log(currentSession);
		setQuestionsLeft(
			currentSession?.questions?.filter(
				(answered) => answered?.answerIndex === -1
			).length
		);
		if (currentSession?._id) {
			window.sessionStorage.setItem(
				"SESSION_DETAILS:" + currentSession?._id,
				JSON.stringify(currentSession)
			);
			window.sessionStorage.setItem("SESSION_ID", currentSession?._id);
			let sessionId = currentSession._id;
			if (mobile) {
				navigate(
					`/TestPlayer/${packageId}/${sessionId}/review=${review}/mobile`
				);
			} else {
				navigate(`/TestPlayer/${packageId}/${sessionId}/review=${review}`);
			}
		}
	}, [currentSession]);

	useEffect(() => {
		setPackageData(PackageDetails);
		if (packageData?.data)
			setNumQuestions(packageData?.data?.questions?.length);
		let quiz = [];

		packageData?.data?.questions.forEach((item, index) => {
			if (packageData) {
				quiz[index] = {
					question: item.question,
					questionId: item._id,
					options: item.options,
					media: item.media ?? false,
				};
			}
		});

		console.log(packageData);

		window.sessionStorage.setItem(
			"QUIZ_DETAILS:" + packageId,
			JSON.stringify(quiz)
		);
		window.sessionStorage.setItem(
			"QUIZ_NAME:" + packageId,
			packageData?.data?.packageName
		);
		window.sessionStorage.setItem(
			"DOMAIN",
			packageData?.data?.tags ? packageData?.data?.tags[0] : "n/a"
		);
		window.sessionStorage.setItem("QUIZ_TYPE", series);
		window.sessionStorage.setItem("USER_TOKEN", authToken);
		window.sessionStorage.setItem("STUDENT_INDEX", studIndex);
	}, [PackageDetails, packageData, packageId]);

	useEffect(() => {
		if (series) {
			if (series !== "n/a" && series !== "Practice" && series !== "Screening")
				navigator.mediaDevices
					.getUserMedia({ video: true })
					.then(function (stream) {
						if (stream.getVideoTracks().length > 0) {
							setWebcamEnabled(true);
						} else {
							setWebcamEnabled(false);
						}
					})
					.catch(function (error) {
						setWebcamEnabled(false);
					});
		}
	}, [series]);

	useEffect(() => {}, [student]);

	useEffect(() => {
		setPackageData(PackageDetails?.data);
		setSeries(PackageDetails?.data?.series[0]);
	}, [PackageDetails]);

	const startTest = async () => {
		let flag = false;
		if (sessions?.data?.length > 0) {
			sessions?.data?.forEach((session) => {
				if (session?.packageId === packageId) {
					setCurrentSession(session);
					flag = true;
				}
			});
		}
		if (!flag) {
			await createTestSession().then((res) => {
				setCurrentSession(res?.data);
				createActivity({
					sessionId: res?.data?._id,
					type: "START_SESSION",
				});
			});
		}
	};

	const reviewSession = async () => {
		const reviewSession = await reviewSessionsByPackageId(packageId);
		setReview(true);
		setCurrentSession(reviewSession?.data?.data);
		setSessionCreated(reviewSession?.data?.success);
	};

	return (
		<>
			{series === "Practice" || series === "Screening" ? (
				<>
					<PracticeStartLayout
						mobile={mobile}
						navigateBack={navigateBack}
						studentName={student?.fullName}
						testStatus={testStatus}
						pageData={pageData}
						packageName={packageData?.data?.packageName}
						startTest={startTest}
						reviewSession={reviewSession}
					/>
				</>
			) : series?.slice(0, 4) === "Mock" || series?.slice(0, 2) === "L-" ? (
				<TestStartLayout
					mobile={mobile}
					logOutHandler={clearCurrentUser}
					navigateBack={navigateBack}
					studentName={student?.fullName}
					numQuestions={numQuestions}
					questionsLeft={
						session?.questions?.filter(
							(question) => question.answerIndex === undefined
						).length ?? 30
					}
					timeLeft={timeLeft}
					//attemptedQuestions={currentSessio}
					totalDurationMin={packageData?.data?.totalDurationMin}
					testStatus={testStatus}
					pageData={pageData}
					packageName={packageData?.data?.packageName}
					startTest={startTest}
					reviewSession={reviewSession}
					webcamEnabled={webcamEnabled}
					webcamRef={webcamRef}
				/>
			) : (
				<CircularProgress style={{ margin: 100 }} />
			)}
		</>
	);
};
