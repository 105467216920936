import { Endpoints } from "../environments/endpoints";
import { environment } from "../environments/environment";
import axiosInstance from "../services/api.service";
import querystring from "querystring";

const useTests = () => {
	const getPackageList = async (body) => {
		if (body.grade) {
			const queryParams = querystring.stringify(body);
			const endpoint = environment.packages + `/?${queryParams}`;
			return await axiosInstance().get(endpoint);
		}
	};

	const getPackageById = async (packageId, admin) => {
		let endpoint = environment.packages + "/" + packageId;
		if (admin) {
			endpoint += `?admin=${admin}`;
		}
		return await axiosInstance().get(endpoint);
	};

	const createTest = async (body) => {
		const endpoint = environment.sessionsUrl + Endpoints.SESSIONS;
		return await axiosInstance().post(endpoint, body);
	};

	const getTestList = async () => {
		const endpoint = environment.testLiveolympiad + Endpoints.TEST_LIST;
		return await axiosInstance().get(endpoint);
	};
	const getSessions = async (studentIndex) => {
		const endpoint = environment.activities + "/sessions/" + studentIndex;
		return await axiosInstance().get(endpoint);
	};
	const getTests = async (userId, packageId) => {
		const endpoint =
			environment.testLiveolympiad +
			Endpoints.TESTS +
			`/sessionsOfUserFromPackageId/${userId}/${packageId}`;
		return await axiosInstance().get(endpoint);
	};

	const getSessionsByPackageId = async (packageId) => {
		const endpoint =
			environment.sessionsUrl + Endpoints.SESSIONS + "/" + packageId;
		return await axiosInstance().get(endpoint);
	};

	const reviewSessionsByPackageId = async (packageId, response) => {
		const endpoint =
			environment.sessionsUrl + Endpoints.SESSIONS + "/review/" + packageId;
		return await axiosInstance()
			.get(endpoint)
			.then(response)
			.catch((e) => {
				throw e;
			});
	};

	const screenCapture = async (studentId, packageId, data) => {
		const endpoint =
			environment.proctoring +
			`/snapshot?studentId=${studentId}&packageId=${packageId}`;
		return await axiosInstance().post(endpoint, data);
	};

	const getShanpshots = async (studentId, packageId) => {
		const endpoint =
			environment.proctoring +
			`/snapshot?studentId=${studentId}&packageId=${packageId}`;
		return await axiosInstance().get(endpoint);
	};

	return {
		getPackageList,
		getPackageById,
		createTest,
		getTestList,
		getSessions,
		getTests,
		getSessionsByPackageId,
		reviewSessionsByPackageId,
		screenCapture,
		getShanpshots,
	};
};
export default useTests;
