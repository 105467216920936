import { Box, Grid } from "@mui/material";
import { CenterButton } from "../../components/CenterButton";
import { CenterText } from "../../components/CenterText";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../components/CustomButton";
export const KnowledgeTree = () => {
	const navigate = useNavigate();
	return (
		<Grid
			container
			style={{
				backgroundColor: "#F7F8F9",
				borderRadius: "20px",
				padding: "30px",
				marginBottom: "20px",
			}}
		>
			<Grid item md={12} lg={12}>
				<CenterText
					text="LiveOlympiad"
					variant="h4"
					fontFamily="Poppins"
					fontWeight="700"
					fontSize="24px"
				/>
				<CenterText
					text="Knowledge Tree"
					variant="h4"
					fontFamily="Poppins"
					fontWeight="700"
					fontSize="24px"
				/>
				<Box height={20} />
				<CenterText
					text="Learn about Exempler & Challenger Series,Evaluation Criteria.About Live Olympiad,Queries & FAQs,Syllabus through an automated Chatbot."
					variant="body2"
					fontFamily="Poppins"
					color="#6A707C"
				/>
				<Box height={25} />
				<Grid container>
					<Grid
						item
						md={12}
						lg={12}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<CustomButton
							btnText="GET STARTED"
							variant="contained"
							color="primary"
							sx={{ width: "191px", height: "48px", borderRadius: "50px" }}
							onClick={() => {
								navigate("/subjects");
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
