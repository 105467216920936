import { Box, Grid, Typography, Button, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useMemo } from "react";
import { UilTimes } from "@iconscout/react-unicons";

const GoldHeader = () => {
	const navigate = useNavigate();
	const isSmallScreen = useMediaQuery("(max-width:600px)");
	const theme = useTheme();
	const school = JSON.parse(sessionStorage.getItem("school"));
	const session = JSON.parse(sessionStorage.getItem("session"));
	const olympiad = JSON.parse(sessionStorage.getItem("package"));

	const isQualified = useMemo(() => {
		return (
			session?.certificate === "MERIT" || session?.certificate === "EXCELLENCE"
		);
	}, [session]);
	let status = session?.isTopper
		? "Global Topper"
		: session?.isGold
		? "National Merit"
		: session?.isSilver
		? "School Merit"
		: "Participation";

	const formattedDate = useMemo(() => {
		const options = { year: "numeric", month: "short", day: "numeric" };
		if (olympiad?.startTime) {
			return new Date(olympiad?.startTime.split("T")[0]).toLocaleDateString(
				"en-US",
				options
			);
		}
	}, [olympiad?.startTime]);
	return (
		<Grid container>
			<Grid item xs={12}>
				<Grid
					container
					sx={{
						backgroundColor: "#4545A5",
						borderRadius: "10px 10px 0px 0px",
						height: "auto",
						padding: 1,
						display: { xs: "flex", sm: "none", md: "none", lg: "none" },
					}}
				>
					<Grid
						item
						xs={8}
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							alignItems: "flex-start",
						}}
					>
						<Typography
							style={{
								fontFamily: "Poppins",
								fontSize: "14px",
								fontStyle: "normal",
								fontWeight: "700",
								lineHeight: "29px",
								color: "#FFFFFF",
							}}
						>
							MATHS OLYMPIAD - Regional
						</Typography>
						<Typography
							style={{
								fontFamily: "Poppins",
								fontSize: "12px",
								fontStyle: "normal",
								fontWeight: "400",
								lineHeight: "17px",
								color: "#FFFFFF",
							}}
						>
							Cambridge School
						</Typography>

						<Typography
							mt={0.2}
							sx={{
								fontFamily: "Poppins",
								fontSize: "12px",
								fontStyle: "normal",
								fontWeight: "400",
								lineHeight: "17px",
								color: "#FFFFFF",
							}}
						>
							12 Nov, 2022
						</Typography>

						<Box mt={0.5}>
							{" "}
							<img src="images/maths.png" alt="maths71" />
						</Box>
					</Grid>
					<Grid
						item
						xs={4}
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Button sx={{ color: "#FFFFFF" }}>
							<UilTimes
								onClick={() => {
									navigate("/dashboard");
								}}
							/>
						</Button>{" "}
					</Grid>
				</Grid>
				<Grid
					container
					sx={{
						backgroundColor: "#4545A5",
						borderRadius: "10px 10px 0px 0px",
						height: "auto",
						padding: 2,
						display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
					}}
				>
					<Grid
						item
						lg={4}
						md={6}
						sm={6}
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							alignItems: "flex-start",
						}}
					>
						<Typography
							style={{
								fontFamily: "Poppins",
								fontSize: "24px",
								fontStyle: "normal",
								fontWeight: "700",
								lineHeight: "29px",
								color: "#FFFFFF",
							}}
						>
							{session?.subjects[0]} Level {session?.series[0].slice(-2)}&nbsp;
							{session?.series[0].includes("L-")
								? "Olympiad"
								: session?.series[0].includes("Mock-")
								? "Mock"
								: "Practice"}
						</Typography>
						<Typography
							mt={0.4}
							style={{
								fontFamily: "Poppins",
								fontSize: "14px",
								fontStyle: "normal",
								fontWeight: "400",
								lineHeight: "17px",
								color: "#FFFFFF",
							}}
						>
							{school?.name}
						</Typography>
						<Box>
							{" "}
							<img src="images/maths.png" alt="maths71" />
						</Box>
					</Grid>
					<Grid item lg={4} md={2} sm={2}>
						<Box
							sx={{
								background: "#FFFFFF",
								borderRadius: "15px",
								width: "48px",
								height: "48px",
								alignItems: "center",
								justifyContent: "center",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Typography
								sx={{
									fontFamily: "Poppins",
									fontSize: { xs: "18px", sm: "21px" },
									fontWeight: 400,
									fontStyle: "normal",
									lineHeight: { xs: "22px", sm: "25px" },
									color: "#000000",
								}}
							>
								{formattedDate
									?.split(" ")[1]
									.substring(0, formattedDate.split(" ")[1].length - 1)}
							</Typography>
							<Typography
								sx={{
									fontFamily: "Poppins",
									fontSize: { xs: "10px", sm: "12px" },
									fontWeight: 400,
									fontStyle: "normal",
									lineHeight: { xs: "10px", sm: "12px" },
									color: "#000000",
								}}
							>
								{formattedDate?.split(" ")[0]}
							</Typography>
						</Box>
					</Grid>
					{isQualified && (
						<Grid
							Item
							lg={3}
							md={3}
							sm={3}
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-end",
								alignItems: "flex-end",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Typography
									style={{
										fontFamily: "Inter",
										fontSize: "10px",
										fontStyle: "normal",
										fontWeight: "500",
										color: "#FFFFFF",
										width: "110px",
										textAlign: "center",
									}}
								>
									Qualified for
								</Typography>
								<Box mt={1}>
									<img src="images/badge.png" alt="Badge" />
								</Box>
								<Typography
									sx={{
										fontFamily: "Inter",
										fontSize: { xs: "12px", sm: "14px" },
										fontWeight: 700,
										fontStyle: "normal",
										color: "#FFFFFF",
										width: "160px",
										textAlign: "center",
									}}
								>
									Nationals with {session?.certificate}
								</Typography>
							</Box>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};
export default GoldHeader;
