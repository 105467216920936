import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const Autocomplete = ({ value, options, onChange }) => {
	const [inputValue, setInputValue] = useState(value);
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(anchorEl ?? event.currentTarget);
	};

	const filteredOptions = options.filter((option) =>
		new RegExp(`.*${inputValue}.*`, "i").test(option?.label)
	);

	useEffect(() => {}, [isDropdownOpen]);

	const handleInputChange = (event) => {
		setInputValue(event.target.value);
		setDropdownOpen(true); // Open the dropdown when typing
		handleClick(event);
	};

	const handleSelectOption = (selectedOption) => {
		setInputValue(selectedOption || inputValue);
		onChange(selectedOption || inputValue);
		setDropdownOpen(false);
	};

	const handleClickAway = () => {
		setDropdownOpen(false);
	};

	return (
		<div>
			<TextField
				label="School"
				variant="outlined"
				value={inputValue?.label}
				onClick={(e) => {
					setDropdownOpen(true);
					handleClick(e);
				}}
				onChange={handleInputChange}
			/>
			<Popper
				open={isDropdownOpen}
				anchorEl={anchorEl}
				sx={{ backgroundColor: "white", zIndex: 100 }}
				disablePortal
			>
				<Paper>
					<ClickAwayListener onClickAway={handleClickAway}>
						<Paper
							sx={{
								height: "300px",
								overflowY: "auto",
								backgroundColor: "white",
								minWidth: "40%",
							}}
						>
							{filteredOptions.map((option) => (
								<MenuItem
									key={option?.id}
									onClick={() => handleSelectOption(option)}
								>
									{option?.label}
								</MenuItem>
							))}
						</Paper>
					</ClickAwayListener>
				</Paper>
			</Popper>
		</div>
	);
};

export default Autocomplete;
