import { Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import React from "react";
import { useQuery } from "react-query";
import useItems from "../../hooks/useItems";
import { SubjectSelector } from "../../components/SubjectSelector";

let numericalGrade = 0;

export const BookletLayout = (props) => {
	const [grade, setGrade] = useState("");

	const subjectJson = {
		1: "Science",
		2: "Maths",
		3: "English",
	};

	
	return (
		<Grid container mt={1}>
			<Grid item p={2} xs={12} sm={12} md={8} lg={7}>
				<Typography variant="h6">
					Click on one of the subjects to view its booklet
				</Typography>
				<Grid container mb={5} alignItems="center">
					<Grid item xs={12} md={12} lg={8}>
						<SubjectSelector setPage={"Booklet"}/>
					</Grid>
					<Grid item xs={12} md={12} lg={4}></Grid>
				</Grid>

				{/* {props.page === 2 &&
					window.location.replace(props.links.get(`math${numericalGrade}`))}

				{props.page === 1 &&
					window.location.replace(props.links.get(`science${numericalGrade}`))}

				{props.page === 3 &&
					window.location.replace(props.links.get(`eng${numericalGrade}`))} */}
			</Grid>
		</Grid>
	);
};