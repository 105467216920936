import React, { useEffect } from "react";
import HomeLayout from "../../designs/Dashboard/HomeLayout";
import { useState } from "react";
import { BookletLayout } from "../../designs/Dashboard/BookletLayout";
import { navigateAsPerSessionValidity } from "../../services/helpers";
import { useStore } from "../../stores";
import { useSchool } from "../../hooks/useSchool";

const Booklet = () => {
	

	return (
		<HomeLayout>
			<BookletLayout />
		</HomeLayout>
	);
};
export default Booklet;