export const responsiveStype = {
	default: {
		hide_mobile: {
			display: { xs: "none", sm: "none", md: "block", lg: "block" },
		},
		hide_desktop: {
			display: { xs: "block", sm: "block", md: "none", lg: "none" },
		},
	},
	OnboardingLayout: {
		MainWarpper: { height: { lg: "100vh", xs: "80vh" } },
	},
	AuthLayout: {
		MaincWarpper: { height: { lg: "100vh", xs: "85vh" } },
	},
	CreatePassword: {
		Typo: { textAlign: { xs: "center", lg: "left" }, fontFamily: "Poppins" },
	},
	ResetPassword: {
		Typo: { textAlign: { xs: "center", lg: "left" }, fontFamily: "Poppins" },
	},
	ForgotPassword: {
		Typo: { textAlign: { xs: "center", lg: "left" }, fontFamily: "Poppins" },
	},
	Signup: {
		Typo: { textAlign: { xs: "center", lg: "left" }, fontFamily: "Poppins" },
	},
	Login: {
		Typo: { textAlign: { xs: "center", lg: "left" }, fontFamily: "Poppins" },
	},
	Mobilever: {
		Typo: { textAlign: { xs: "center", lg: "left" }, fontFamily: "Urbanist" },
	},
	Personal: {
		Typo: { textAlign: { xs: "center", lg: "left" }, fontFamily: "Poppins" },
	},
	School: {
		Typo: { textAlign: { xs: "center", lg: "left" }, fontFamily: "Poppins" },
	},
};
