import { Button, CircularProgress, Grid } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Chip, Stack } from '@mui/material';

const TopAction = ({
    practice,
    questions,
    currentQuestion,
    setCurrentQuestion,
    packageName,
    newQuestionLoading
}) => {
    const handlePrev = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion(currentQuestion - 1);
        } else {
            setCurrentQuestion(currentQuestion - 1);
        }
    }

    const handleAnswerButtonClick = () => {
        const nextQuestions = currentQuestion + 1;
        if (nextQuestions < questions.length) {
            setCurrentQuestion(nextQuestions);
        }
        else {
            alert('You reached the end of the quiz');
        }
    }

    return (
        <Grid container paddingTop={2} paddingLeft={2} paddingRight={2}>
            <Grid item={true} md={12} lg={9} p={3} sx={{ backgroundColor: '#8391A1', display:{md:'none', lg:'flex'} }}>
                <Grid item={true} container md={12} lg={12} sx={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                    <Grid item={true} sm={12} md={12} lg={12} sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Stack>
                            <Chip variant='outlined' label={packageName} sx={{color:'#E54545', backgroundColor:'#ffffff', border:'2px solid #E54545'}}/>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
            {newQuestionLoading ? <Grid item={true} lg={3} sx={{ display:{xs: 'none', sm: 'none', md:'none', lg:'flex'}, justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress/>
            </Grid> : <Grid item={true} lg={3} sx={{ display:{xs: 'none', sm: 'none', md:'none', lg:'flex'}, justifyContent: 'center', alignItems: 'center' }}>
                {(!practice) && <Button onClick={handlePrev} disabled={currentQuestion === 0} variant='outlined' startIcon={<ArrowBackIcon />} sx={{ color: '#1C1B1F' }}>PREVIOUS</Button>}
                <Button onClick={handleAnswerButtonClick} disabled={currentQuestion === (questions.length - 1) || questions.length === 0} variant='outlined' endIcon={<ArrowForwardIcon />} sx={{ marginLeft: '20px', color: '#1C1B1F', flex: (practice ? 1 : null) }}>NEXT</Button>
            </Grid>}        
            {newQuestionLoading ? <Grid item={true} sx={{ display:{xs: 'flex', sm: 'flex', md:'flex', lg:'none'}, justifyContent: 'flex-end'}}>
                <CircularProgress style={{alignSelf: 'center', marginLeft: 40, marginTop: 0}}/>
            </Grid> : <Grid style={{marginTop: '15px', marginLeft: '15px'}} item={true} sx={{ display:{xs: 'flex', sm: 'flex', md:'flex', lg:'none'}, justifyContent: 'flex-end'}}>
                {(!practice) && <Button onClick={handlePrev} disabled={currentQuestion === 0} variant='outlined' sx={{ color: '#1C1B1F', border:'2px solid #1C1B1F' }}><ArrowBackIcon /></Button>}  
                <Button onClick={handleAnswerButtonClick} disabled={currentQuestion === (questions.length - 1) || questions.length === 0} variant='outlined' sx={{ marginLeft: '20px', color: '#1C1B1F', border:'2px solid #1C1B1F', flex: (practice ? 1 : null) }}><ArrowForwardIcon /></Button>
            </Grid>}
        </Grid>
        
    );
};

export default TopAction;