import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getErrorMsz } from "../../services/validator";
import { responsiveStype } from "../../beautifiers/responsive";
import useAuthHelper from "../../hooks/useAuthHelper";
import { LoginLayout } from "../../designs/Auth/LoginLayout";
import { useStore } from "../../stores";
import { useQuery } from "react-query";
import { useStudent } from "../../hooks/useStudent";
import { environment } from "../../environments/environment";
import { AuthLayout } from "../../designs/Auth/AuthLayout";

export const Login = () => {
	const navigate = useNavigate();
	const setCurrentUser = useStore((state) => state.setCurrentUser);
	const currentUser = useStore((state) => state.currentUser);

	const [snakeBarProps, setSnakeBarProps] = useState({});

	const [submitFlag] = useState(false);
	const [pageData, setPageData] = useState({ phoneNumber: "", password: "" });
	const [loginSuccess, setLoginSuccess] = useState(false);

	const { login } = useAuthHelper();

	useEffect(() => {
		if (currentUser?.id) {
			navigate("/parent");
		}
	}, [currentUser]);

	const submitHandler = async () => {
		let phone = pageData.phoneNumber;
		console.log("pageData.phoneNumber", phone.split()[0].split());
		let phoneFlag = /^\d{10}$/.test(phone);
		let pinFlag = /^\d{6,8}$/.test(pageData.password);
		if (phone.startsWith("+")) {
			phoneFlag = /^\d{12}$/.test(phone.substring(1));
		} else {
			phone = "+91" + phone;
		}
		if (!phoneFlag) {
			setSnakeBarProps({
				snackbarFlag: true,
				msz: "Please enter valid phone number",
				type: "error",
			});
			return;
		}

		if (!pinFlag) {
			setSnakeBarProps({
				snackbarFlag: true,
				msz: "Please enter valid 6 digit password",
				type: "error",
			});
			return;
		}

		let res = await login({
			userName: phone,
			password: pageData.password,
			loginForced: true,
			mobile: false,
		});
		if (res.data?.success) {
			setSnakeBarProps({
				snackbarFlag: true,
				msz: res.data.message,
				type: "success",
			});
			setCurrentUser(res.data?.data);
			localStorage.setItem("auth", "false");
			navigate("/parent");
			setLoginSuccess(true);
		} else {
			if (
				environment.env !== "school" &&
				res.data?.message.includes("not found")
			)
				window.location.href = "/1";
			setSnakeBarProps({
				snackbarFlag: true,
				msz: res.data.message,
				type: "error",
			});
		}
		// afterValidate(afterValidateCallBack)
	};

	useEffect(() => {
		window.sessionStorage.clear();
	}, []);

	const forgotPage = () => {
		//window.location.href = "/forgotpassword";
		alert("Use LiveOlympiad app on Play Store to reset your pin/password");
	};

	return (
		<AuthLayout>
			<LoginLayout
				forgotPage={forgotPage}
				responsiveStype={responsiveStype}
				submitFlag={submitFlag}
				getErrorMsz={getErrorMsz}
				pageData={pageData}
				setPageData={setPageData}
				navigate={navigate}
				submitHandler={submitHandler}
				snakeBarProps={snakeBarProps}
				setSnakeBarProps={setSnakeBarProps}
			/>
		</AuthLayout>
	);
};
