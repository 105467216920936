import axiosInstance from "../services/api.service";
import { environment } from "../environments/environment";
import { Endpoints } from "../environments/endpoints";

const useAuthHelper = () => {
	const login = async (body) => {
		const endpoint = environment.users + Endpoints.LOGIN_USER;
		return await axiosInstance({ secure: false }).post(endpoint, body);
	};
	return {
		login,
	};
};

export default useAuthHelper;
