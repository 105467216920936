import { Button, Card, Grid, List, ListItem } from "@mui/material"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect, useState } from "react";
import { HintPallette } from "../../../designs/TestPlayer/components/HintPallette";

export const PracticeSidebar = ({
    questions,
    currentQuestion,
}) => {
    const [hints, setHints] = useState([]);
    const [currentHint, setCurrentHint] = useState(0);

    const [concept, setConcept] = useState("");
    const [conceptVisible, setConceptVisible] = useState(false);

    useEffect(() => {
        if (questions) {
            setHints(questions[currentQuestion]?.hints);
            setConcept(questions[currentQuestion]?.explanation);
        }
    }, [questions, currentQuestion])

    useEffect(() => {
        setCurrentHint(null);
        setConceptVisible(false);
    }, [currentQuestion])

    return <Grid container p={2}>
        <Grid item xs={12} md={12} sm={12} lg={12} >
            <Card  sx={{ minHeight: "25px", mb: "1.5rem", padding: "1rem" }}>
                    <Button
                        onClick={() => {
                            setConceptVisible(!conceptVisible)
                        }}>
                        {conceptVisible ? "Hide Concept" : "Show Concept"}
                    </Button>
                {conceptVisible && <>
                    <Grid margin={1}>
                        <span>
                            <div dangerouslySetInnerHTML={{ __html: concept }} />
                        </span>
                    </Grid>
                </>}
            </Card>
        </Grid>
        <Grid item xs={12} md={12} sm={12} lg={12} >
            <Card sx={{ minHeight: "25px", mb: "1.5rem", padding: "1rem" }}>
                <Button 
                    onClick={() => {
                        if (currentHint === null) setCurrentHint(0);
                        else setCurrentHint(null);
                    }}>
                    {currentHint === null ? "Show Hints" : "Hide Hints"}
                </Button>
                {currentHint !== null && <>
                    <HintPallette currentHint={currentHint} hints={hints} setCurrentHint={setCurrentHint}/>
                    <Grid margin={1}>
                        <span>
                        {currentHint === null && <span>Click on one of the tabs to view</span>}
                        {hints ? <div dangerouslySetInnerHTML={{ __html: hints[currentHint] }} /> : <></>}
                        </span>
                    </Grid>
                </>}
            </Card>
        </Grid>
    </Grid>
}