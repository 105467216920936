import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import AlarmIcon from '@mui/icons-material/Alarm';

export const TestTimer = ({ timeLeft, finish, intervalFunction, interval, cooldown, limiter, setLimiter }) => {
    const [delay, setDelay] = useState(-1);
    const [timeOver, setTimeOver] = useState(false);
    const minutes = Math.floor(delay/60);
    const seconds = Math.floor(delay % 60);

    useEffect(() => {
        if (timeLeft !== null) setDelay(timeLeft);
    }, [timeLeft])

    useEffect(() => {
        const timer = setInterval(() => {
            setDelay(delay - 1);
        }, 1000);

        if (delay === 0) {
            clearInterval(timer);
            setTimeOver(true);
        }

        if (limiter && delay !== timeLeft && delay !== 0 && delay % interval === 0) {
            intervalFunction();
        }

        if (!limiter && delay !== timeLeft-cooldown && (delay+cooldown) % interval === 0) {
            setLimiter(true);
        }

        return () => {
            clearInterval(timer);
        };
    });

    useEffect(() => {
        if (timeOver) finish();
    }, [timeOver]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <AlarmIcon sx={{ marginRight: '10px', fontSize: '20px' }} />{minutes} Min {seconds} Sec
        </Box>
    )
}