import { Box, Button, Grid, Typography } from "@mui/material";
import HomeLayout from "../Dashboard/HomeLayout";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import React from "react";
import { useQuery } from "react-query";
import GoldHeader from "../headers/Gold";
import GoldResult from "../Results/goldRes";
import Images from "../Results/Images";
import useTests from "../../hooks/useTests";
import { useStore } from "../../stores";
import GoldAward from "../Results/GoldAward";

const GoldCard = () => {
	const navigate = useNavigate();
	const session = JSON.parse(sessionStorage.getItem("session"));
	const [activeButton, setActiveButton] = useState("result");
	const student = JSON.parse(window.localStorage.getItem("current_student"));
	const currentUser = useStore((state) => state.currentUser);

	const isQualified = useMemo(() => {
		return session?.certificate === "MERIT";
	}, [session]);

	const { getPackageById } = useTests();

	const handleResultClick = () => {
		setActiveButton("result");
	};

	const handleImagesClick = () => {
		setActiveButton("images");
	};

	const handleAwardsClick = () => {
		setActiveButton("awards");
	};

	const { data: packageData } = useQuery(
		[`Package`, session?.packageId],
		() => getPackageById(session?.packageId, true),
		{ enabled: !!session?.packageId }
	);

	const handleReportClick = () => {
		let quiz = [];
		let series = packageData?.data?.series[0];

		packageData?.data?.questions.forEach((item, index) => {
			if (packageData) {
				quiz[index] = {
					question: item.question,
					questionId: item._id,
					options: item.options,
					media: item.media ?? false,
					correctOption: item.options.indexOf(item?.answer),
				};
			}
		});

		window.sessionStorage.setItem(
			"QUIZ_DETAILS:" + session?.packageId,
			JSON.stringify(quiz)
		);
		window.sessionStorage.setItem(
			"QUIZ_NAME:" + session?.packageId,
			packageData?.data?.packageName
		);
		window.sessionStorage.setItem(
			"DOMAIN",
			packageData?.data?.tags ? packageData?.data?.tags[0] : "n/a"
		);
		window.sessionStorage.setItem("QUIZ_TYPE", series);
		window.sessionStorage.setItem("USER_TOKEN", currentUser?.access_token);
		window.sessionStorage.setItem("STUDENT_INDEX", student?.studIndex);
		window.sessionStorage.setItem(
			"SESSION_DETAILS:" + session?._id,
			JSON.stringify(session)
		);
		window.sessionStorage.setItem("SESSION_ID", session?._id);
		navigate(`/TestPlayer/${session?.packageId}/${session?._id}/review=true`);
	};

	return (
		<HomeLayout>
			<Grid
				container
				sx={{
					justifyContent: "center",
					height: "100%",
					background: "rgba(217, 217, 217, 0.3)",
					borderRadius: "10px 10px 10px 10px;",
				}}
			>
				<Grid item xs={12} sm={12} lg={12}>
					<GoldHeader />
				</Grid>
				<Grid
					item
					lg={3}
					xs={3}
					sm={3}
					md={3}
					sx={{
						display: "flex",
						justifyContent: {
							lg: "center",
							md: "center",
							sm: "center",
							xs: "center",
						},
					}}
				>
					<Button
						onClick={handleResultClick}
						sx={{
							backgroundColor:
								activeButton === "result"
									? "rgba(49, 69, 165, 0.3)"
									: "rgba(69, 69, 165, 0.1)",
							width: { xs: "287px", sm: "287px" },
							height: "44px",
							borderRadius: "0px 0px 10px 10px",
						}}
					>
						<Typography
							style={{
								fontFamily: "urbanist",
								fontSize: "18px",
								fontStyle: "normal",
								fontWeight: "500",
								lineHeight: "22px",
								color: "#000000",
							}}
						>
							Result
						</Typography>
					</Button>
				</Grid>
				{/* <Grid
					item
					lg={3}
					xs={3}
					sm={3}
					md={3}
					sx={{
						display: "flex",
						justifyContent: {
							lg: "center",
							md: "center",
							sm: "center",
							xs: "center",
						},
					}}
				>
					<Button
						onClick={handleImagesClick}
						sx={{
							backgroundColor:
								activeButton === "images"
									? "rgba(69, 69, 165, 0.3)"
									: "rgba(69, 69, 165, 0.1)",
							width: { xs: "287px", sm: "287px" },
							height: "44px",
							borderRadius: "0px 0px 10px 10px",
						}}
					>
						<Typography
							style={{
								fontFamily: "urbanist",
								fontSize: "18px",
								fontStyle: "normal",
								fontWeight: "500",
								lineHeight: "22px",
								color: "#000000",
							}}
						>
							Images
						</Typography>
					</Button>
				</Grid> */}
				{isQualified && (
					<Grid
						item
						lg={3}
						xs={3}
						sm={3}
						md={3}
						sx={{
							display: "flex",
							justifyContent: {
								lg: "center",
								md: "center",
								sm: "center",
								xs: "center",
							},
						}}
					>
						<Button
							onClick={handleAwardsClick}
							sx={{
								backgroundColor:
									activeButton === "images"
										? "rgba(69, 69, 165, 0.3)"
										: "rgba(69, 69, 165, 0.1)",
								width: { xs: "287px", sm: "287px" },
								height: "44px",
								borderRadius: "0px 0px 10px 10px",
							}}
						>
							<Typography
								style={{
									fontFamily: "urbanist",
									fontSize: "18px",
									fontStyle: "normal",
									fontWeight: "500",
									lineHeight: "22px",
									color: "#000000",
								}}
							>
								Awards
							</Typography>
						</Button>
					</Grid>
				)}
				{/* <Grid
					item
					lg={3}
					xs={3}
					sm={3}
					md={3}
					sx={{
						display: "flex",
						justifyContent: {
							lg: "center",
							md: "center",
							sm: "center",
							xs: "center",
						},
					}}
				>
					<Button
						onClick={handleReportClick}
						sx={{
							backgroundColor:
								activeButton === "images"
									? "rgba(69, 69, 165, 0.3)"
									: "rgba(69, 69, 165, 0.1)",
							width: { xs: "287px", sm: "287px" },
							height: "44px",
							borderRadius: "0px 0px 10px 10px",
						}}
					>
						<Typography
							style={{
								fontFamily: "urbanist",
								fontSize: "18px",
								fontStyle: "normal",
								fontWeight: "500",
								lineHeight: "22px",
								color: "#000000",
							}}
						>
							Detailed Report
						</Typography>
					</Button>
				</Grid> */}
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						width: "100%",
						pt: 2,
					}}
				>
					{activeButton === "result" && <GoldResult session={session} />}
					{activeButton === "images" && (
						<Box>
							<Images studentId={student._id} packageId={session.packageId} />
						</Box>
					)}
					{activeButton === "awards" && (
						<Box>
							<GoldAward session={session} student={student} />
						</Box>
					)}
				</Box>
			</Grid>
		</HomeLayout>
	);
};
export default GoldCard;
