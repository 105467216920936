import { Box, Grid, Typography } from "@mui/material";
import { CenterButton } from "../../components/CenterButton";
import { SubjectBox } from "../../components/SubjectBox";
import React from "react";

const colorMap = {
	Science: "#42BBEF",
	Maths: "#4545A5",
	English: "red",
};
export const UpgradeBox = ({ subscriptions, subjects }) => {
	return (
		<Grid
			container
			style={{ borderRadius: "20px", padding: "24px", marginBottom: "20px" }}
		>
			<Grid item md={12} lg={12}>
				<Typography
					variant="subtitle"
					justifyContent="center"
					alignItems="center"
				>
					<Box
						mb={3}
						mt={1}
						sx={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "14px" }}
					>
						You're Currently on {subscriptions?.length} Subjects Subscription,
						{subscriptions?.length < 3 &&
							"upgrade license to avail more benefits!"}
					</Box>
				</Typography>
			</Grid>
			{subjects &&
				subjects?.map((subject) => (
					<SubjectBox
						key={subject?.id}
						text={subject?.name}
						color={
							subscriptions?.includes(subject?.id)
								? "white"
								: colorMap[subject?.name]
						}
						borderRadius="10px"
						bgColor={
							subscriptions?.includes(subject?.id)
								? colorMap[subject?.name]
								: ""
						}
						border={`1px solid ${colorMap[subject?.name]}`}
					/>
				))}
			{/* <SubjectBox
				text="Mathematics"
				color="#4545A5"
				borderRadius="10px"
				border="1px solid #4545A5"
			/>
			<SubjectBox
				text="Science"
				color="#42BBEF"
				borderRadius="10px"
				border="1px solid #42BBEF"
			/>
			<SubjectBox
				text="English"
				color="red"
				borderRadius="10px"
				border="1px solid red"
			/> */}
			{/* <CenterButton 
            text="Upgrade" 
            color='#FFFFFF'
            buttonColor='secondary'
            borderRadius="60px"
            width='163px'
            p='15px 15px 15px 15px'
            height='48px'
        /> */}
		</Grid>
	);
};
