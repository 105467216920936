import {
	Avatar,
	Box,
	Button,
	Chip,
	Grid,
	IconButton,
	Stack,
	Typography,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import MicNoneIcon from "@mui/icons-material/MicNone";
import { TestTimer } from "../../../components/TestTimer";
import Webcam from "react-webcam";
import { useNavigate, useParams } from "react-router-dom";
import useTests from "../../../hooks/useTests";
import { environment } from "../../../environments/environment";
import { useStore } from "../../../stores";

const Pallette = ({
	mobile,
	packageId,
	currentSession,
	questions,
	currentQuestion,
	setCurrentQuestion,
	statusList,
	timeOver,
	review,
	questionStatusList,
}) => {
	const student = JSON.parse(localStorage.getItem("current_student"));
	const params = useParams();
	const navigate = useNavigate();

	let sessionId = params.sessionId;

	let curentUser = useStore((state) => state.currentUser);

	const [webcamEnabled, setWebcamEnabled] = useState(
		environment.env !== "school"
	);
	const [limiter, setLimiter] = useState(true);
	const [img, setImg] = useState(null);
	const [counter, setCounter] = useState(101);

	const { screenCapture } = useTests();

	const webcamRef = useRef(null);

	// const toggleCamera = () => {
	//     if (webcamEnabled) setWebcamEnabled(false);
	//     else setWebcamEnabled(true);
	// }

	function dataURItoBlob(dataURI) {
		const byteString = atob(dataURI.split(",")[1]);
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ab], { type: "image/png" });
	}

	const capture = useCallback(() => {
		if (webcamEnabled) {
			const imageSrc = webcamRef.current.getScreenshot();
			setLimiter(false);
			setImg(imageSrc);
		}
	}, [webcamRef]);

	const buttonColor = (index, question) => {
		if (index === currentQuestion) return "#838BA1";
		if (
			question.status === "attempted" ||
			questions[index]?.selectedOption > -1
		)
			return "#42BBEF";
		if (review === "review=false") {
			if (question.status === "skipped") return "#FCBD42";
			return "#ffffff";
		} else return "#FCBD42";
	};

	useEffect(() => {
		if (review == "review=true") {
			setWebcamEnabled(false);
			return;
		}
		navigator.mediaDevices
			.getUserMedia({ video: true })
			.then(function (stream) {
				if (stream.getVideoTracks().length > 0) {
					setWebcamEnabled(true);
				} else {
					setWebcamEnabled(false);
					if (!mobile) navigate(`/sessionStart/` + packageId);
				}
			})
			.catch(function (error) {
				if (!mobile) navigate(`/sessionStart/` + packageId);
				setWebcamEnabled(false);
			});
	});

	useEffect(() => {
		if (img) {
			const data = new FormData();
			const blob = dataURItoBlob(img);

			data.append("file", blob, "image.png");
			screenCapture(student?._id, packageId, data);
			setCounter(counter + 1);
		}
	}, [img]);

	return (
		<Grid container>
			<Grid
				item={true}
				container
				md={12}
				lg={12}
				p={3}
				sx={{ backgroundColor: "#F7F8F9" }}
			>
				<Grid item={true} container md={12} lg={12} mb={2} alignItems="center">
					<Grid item={true} md={9} lg={10}>
						<Typography
							component={"span"}
							variant="body1"
							sx={{ color: "#E54545" }}
						>
							<TestTimer
								timeLeft={currentSession?.timeLeft}
								finish={timeOver}
								intervalFunction={
									mobile
										? () => {
												console.log("Interval");
										  }
										: capture
								}
								interval={30}
								cooldown={6}
								limiter={limiter}
								setLimiter={setLimiter}
							/>
						</Typography>
					</Grid>
					<Grid
						item={true}
						md={3}
						lg={2}
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
						}}
					>
						<IconButton>
							<MoreVertIcon />
						</IconButton>
					</Grid>
				</Grid>
				<Grid
					item={true}
					md={12}
					lg={12}
					mb={2}
					sx={{ display: { md: "none", lg: "flex" } }}
				>
					<Stack direction="row" spacing={1}>
						<Chip
							label="ALL"
							variant="filled"
							sx={{ color: "#ffffff", backgroundColor: "#838BA1" }}
						/>
						<Chip
							label={statusList[0] + statusList[3] + " PENDING"}
							variant="outlined"
							sx={{ color: "#1E232C", backgroundColor: "#ffffff" }}
						/>
						<Chip
							label={statusList[2] + " DONE"}
							variant="filled"
							sx={{ color: "#1E232C", backgroundColor: "#42BBEF" }}
						/>
						<Chip
							label={statusList[1] + " SKIPPED"}
							variant="filled"
							sx={{ color: "#1E232C", backgroundColor: "#FCBD42" }}
						/>
					</Stack>
				</Grid>
				<Grid
					item={true}
					container
					md={12}
					lg={12}
					mb={2}
					sx={{ display: { md: "none", lg: "flex" } }}
				>
					{questions.map((question, index) => {
						return (
							<Grid
								key={index}
								item={true}
								md={2}
								lg={2}
								mb={2}
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<IconButton
									onClick={() => {
										setCurrentQuestion(index);
									}}
								>
									<Avatar
										sx={{
											width: "32px",
											height: "32px",
											color: "#1E232C",
											backgroundColor: buttonColor(index, question),
											border: "0.25px solid #1C1B1F",
											fontSize: "14px",
										}}
									>
										{index + 1}
									</Avatar>
								</IconButton>
							</Grid>
						);
					})}
				</Grid>
				{environment.env !== "school" && !mobile && (
					<Grid
						item={true}
						container
						md={12}
						lg={12}
						p={2}
						sx={{ border: "2px solid lightgray", borderadius: "10px" }}
					>
						<Grid
							item={true}
							md={8}
							lg={8}
							sx={{ display: { md: "none", lg: "block" } }}
						>
							<Box sx={{ height: "135px", width: "100%", borderadius: "10px" }}>
								{webcamEnabled ? (
									<Webcam
										height="135px"
										style={{ borderRadius: 10 }}
										imageSmoothing={true}
										screenshotFormat="image/jpeg"
										mirrored={true}
										ref={webcamRef}
									/>
								) : (
									<></>
								)}
							</Box>
						</Grid>
						{/* <Grid item={true} container md={12} lg={4} justifyContent='center' alignItems='center'>
                        <Grid item={true} md={6} lg={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Button onClick={toggleCamera} startIcon={<PhotoCameraOutlinedIcon />} endIcon={<DoneIcon />} sx={{ backgroundColor: webcamEnabled ? "#838BA1" : "#ffffff" }} />
                        </Grid>
                        <Grid item={true} md={6} lg={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button onClick={capture} startIcon={<MicNoneIcon />} endIcon={<CloseIcon />} sx={{ backgroundColor: "#ffffff" }} />
                        </Grid>
                    </Grid>*/}
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

export default Pallette;
