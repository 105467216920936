import {
	Avatar,
	Box,
	Chip,
	Grid,
	IconButton,
	Stack,
	Typography,
	Divider,
	ListItemIcon,
	Menu,
	MenuItem,
	Tooltip,
} from "@mui/material";
import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { ArrowCircleLeft } from "@mui/icons-material";
import { Navigate, useNavigate } from "react-router-dom";
import { default as logo } from "../../assets/brand.svg";
import { CustomButton } from "../../components/CustomButton";
import { useStore } from "../../stores";

function stringToColor(string) {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = "#";

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}
	/* eslint-enable no-bitwise */

	return color;
}
function stringAvatar(name) {
	if (name?.split(" ")[0] && name?.split(" ")[1]) {
		return {
			sx: {
				bgcolor: stringToColor(name),
				width: { lg: "56px", xs: "30px" },
				height: { lg: "56px", xs: "30px" },
			},
			children: `${name?.split(" ")[0][0]}${name?.split(" ")[1][0]}`,
		};
	} else if (name?.split(" ")[0][0]) {
		return {
			sx: {
				bgcolor: stringToColor(name),
				width: { lg: "56px", xs: "30px" },
				height: { lg: "56px", xs: "30px" },
			},
			children: `${name?.split(" ")[0][0]}`,
		};
	}
}
const Header = ({ test, callback, finishCheck, btnText }) => {
	const navigate = useNavigate();
	const student = JSON.parse(window.localStorage.getItem("current_student"));
	const clearCurrentUser = useStore((state) => state.clearCurrentUser);
	const val = localStorage.getItem("single");
	return (
		<Grid
			container
			px={4}
			py={2}
			alignItems="center"
			sx={{ backgroundColor: "#4545A5" }}
		>
			<Grid item xs={10.5} sm={10.5} md={8.5} lg={8.5}>
				<Box>
					<img
						src={logo}
						style={{ minWidth: "100px", width: "70%", maxWidth: "180px" }}
					/>
				</Box>
			</Grid>
			<Grid
				item
				xs={5}
				sm={4}
				md={3}
				lg={3}
				sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}
			>
				<Box
					sx={{
						backgroundColor: "white",
						borderRadius: "50px",
						padding: "7px",
					}}
				>
					<Grid container alignItems="center">
						<Grid item xs={7} md={9.5} lg={10} sm={9}>
							<Grid container>
								<Grid
									item
									md={12}
									lg={12}
									xs={8}
									sx={{ pl: { lg: 4, xs: 1, sm: 2 } }}
								>
									<Typography variant="subtitle2">
										<Box
											sx={{
												fontFamily: "Urbanist",
												fontWeight: 450,
												fontSize: { lg: "18px", xs: "16px" },
												marginTop: { lg: "0px", md: "4px", xs: "10px" },
											}}
										>
											{student?.fullName}
										</Box>
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item lg={2} md={2.5}>
							<Avatar style={{ marginTop: 5 }} src={student?.profileImageurl} />
						</Grid>
					</Grid>
				</Box>
			</Grid>
			<Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}>
				{!test && (
					<Tooltip title={val === "false" ? "Children" : "Logout"}>
						<span>
							<IconButton
								size={"medium"}
								onClick={() => {
									if (val === "true") {
										clearCurrentUser(false);
										localStorage.removeItem("auth");
										navigate("/");
									} else {
										navigate("/parent");
									}
								}}
							>
								{val === "false" ? (
									<ArrowCircleLeft
										sx={{ color: "#FCBD42" }}
										fontSize={"large"}
									/>
								) : (
									<LogoutIcon sx={{ color: "#FCBD42" }} fontSize={"medium"} />
								)}
							</IconButton>
						</span>
					</Tooltip>
				)}
				<Box pr={10}>
					{test && finishCheck && (
						<CustomButton
							onClick={callback}
							btnText={btnText}
							variant="contained"
							sx={{
								":hover": { bgcolor: "#8B8000", color: "white" },
								p: 1,
								fontFamily: "Urbanist",
								color: "black",
								borderadius: "16px",
								width: "80px",
								height: "36px",
								fontSize: "12px",
								backgroundColor: "#F9BB47",
								fontWeight: 600,
							}}
						/>
					)}
				</Box>
			</Grid>
		</Grid>
	);
};

export default Header;
