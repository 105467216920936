import { Box, Grid } from "@mui/material";
import { KnowledgeTree } from "../Auth/KnowledgeTreeBox";
import { MobileUpgrade } from "../Auth/MobileUpgrade";
import { CustomListItem } from "../../components/CustomListItem";
import { SubjectSelector } from "../../components/SubjectSelector";
import { UpgradeBox } from "./UpgradeBox";
import Olympiads from "./Olympiads";
import { useMemo } from "react";
// import { CustomListItem } from "../../components/CustomListItem"
let nationalOlympiad = {
	_id: "sample_id",
	packageName: "National Level Olympiad",
	startTime: new Date("2024-01-28").toISOString(),
};
export const DashboardLayout = ({
	olympiads,
	isNew,
	mocks,
	subject,
	setSubject,
	sessions,
	subscriptions,
	subscriptionSubject,
	subjects,
}) => {
	const qualification = useMemo(() => {
		let qualification = {};
		sessions?.forEach((session) => {
			if (
				session?.certificate === "EXCELLENCE" ||
				session?.certificate === "MERIT"
			) {
				qualification[session?.subjects[0]] = true;
			}
		});
		return qualification;
	}, [sessions]);
	return (
		<Grid container mt={1}>
			<Grid item xs={12} sm={12} md={12} lg={8}>
				<Olympiads
					olympiads={olympiads}
					sessions={sessions}
					qualification={qualification}
					isNew={isNew}
					subscriptionSubject={subscriptionSubject}
				/>
				<SubjectSelector subject={subject} setSubject={setSubject} />
				{mocks?.map((mock) => (
					<Box key={mock?._id} sx={{ marginTop: 2 }}>
						{mock?.subject[0] === subject && (
							<CustomListItem mockTest={mock} sessions={sessions} />
						)}
					</Box>
				))}
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={4} sx={{ pl: 2 }}>
				<UpgradeBox subscriptions={subscriptions} subjects={subjects} />
				<KnowledgeTree />
			</Grid>
		</Grid>
	);
};
