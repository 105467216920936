import { Chip, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { CustomButton } from "./CustomButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import { useNavigate } from "react-router-dom";
import React, { useMemo } from "react";
import { Assessment, Report } from "@mui/icons-material";

export const CustomListItem = ({ mockTest, sessions }) => {
	const formattedDate = useMemo(() => {
		const options = { year: "numeric", month: "short", day: "numeric" };
		if (mockTest?.startTime) {
			return new Date(mockTest?.startTime.split("T")[0]).toLocaleDateString(
				"en-US",
				options
			);
		}
	}, [mockTest?.startTime]);

	const session = useMemo(() => {
		let attemptedSession = null;
		if (sessions) {
			sessions?.map((session) => {
				if (session?.packageId === mockTest?._id) {
					attemptedSession = session;
				}
			});
		}
		return attemptedSession;
	}, [sessions, mockTest]);

	const attemptedQuestions = useMemo(() => {
		return session
			? session?.questions.filter(
					(question) =>
						question?.answerIndex !== null &&
						question?.answerIndex !== undefined
			  ).length
			: 0;
	}, [session]);

	const formattedStartTime = useMemo(() => {
		if (mockTest?.startTime) {
			const date = new Date(mockTest?.startTime);
			const localDate = date.toLocaleString();
			let time = localDate.split(" ")[1];
			return time;
		}
	}, [mockTest?.startTime]);

	const formattedEndTime = useMemo(() => {
		if (mockTest?.endTime) {
			const date = new Date(mockTest?.endTime);
			const localDate = date.toLocaleString();
			let time = localDate.split(" ")[1];
			return time;
		}
	}, [mockTest?.endTime]);

	const navigate = useNavigate();
	return (
		<Box>
			<Chip
				label={mockTest?.packageName}
				color="primary"
				sx={{
					fontFamily: "Poppins",
					fontSize: "14px",
					fontWeight: 600,
					height: "30px",
					borderRadius: "10px 10px 0px 0px",
				}}
			/>
			<Grid
				container
				style={{
					backgroundColor: "#F7F8F9",
					borderRadius: "0px 20px 20px 20px",
					padding: "20px",
					marginBottom: "20px",
				}}
			>
				<Grid
					item
					xs={12}
					sm={6}
					md={4}
					lg={4}
					style={{ justifyContent: "flex-start", alignItems: "center" }}
				>
					<Box variant="body2" mt={1}>
						<Box
							sx={{
								fontFamily: "Poppins",
								fontWeight: 500,
								color: "rgba(106,112,124,1)",
							}}
						>
							{" "}
							{formattedDate}
						</Box>
					</Box>
					<Box
						variant="body2"
						mt={1}
						sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
					>
						<Box
							sx={{
								fontFamily: "Poppins",
								fontWeight: 500,
								color: "rgba(106,112,124,1)",
							}}
						>
							Opens at {formattedStartTime}
						</Box>
						<Box
							sx={{
								fontFamily: "Poppins",
								fontWeight: 500,
								color: "rgba(106,112,124,1)",
							}}
						>
							Closes at {formattedEndTime}
						</Box>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					md={4}
					lg={4}
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Stack>
						<Typography
							sx={{
								fontFamily: "Poppins",
								fontWeight: 500,
								color: "rgba(106,112,124,1)",
							}}
						>
							Questions:- {mockTest?.questions?.length}
						</Typography>
						<Typography
							sx={{
								fontFamily: "Poppins",
								fontWeight: 500,
								color: "rgba(106,112,124,1)",
							}}
						>
							Attempted:- {attemptedQuestions}
						</Typography>
					</Stack>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					md={8}
					lg={4}
					style={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
					}}
				>
					{attemptedQuestions === mockTest?.questions?.length ||
					session?.timeLeft === 0 ? (
						<CustomButton
							onClick={() => {
								sessionStorage.setItem("session", JSON.stringify(session));
								navigate(`/report`);
							}}
							btnText="Report"
							endIcon={<Assessment />}
							variant="contained"
							sx={{
								color: "white",
								borderRadius: "20px",
								width: "105px",
								height: { xs: "34px", lg: "36px" },
								fontSize: { xs: "8px", lg: "12px" },
								backgroundColor: "#1fd655",
								fontWeight: 600,
							}}
						/>
					) : (
						<CustomButton
							onClick={() => {
								sessionStorage.setItem("session", JSON.stringify(session));
								var flag = false;
								console.log(mockTest?.startTime);
								if (
									mockTest?.startTime != null &&
									new Date(mockTest?.startTime) > new Date()
								) {
									alert(
										`You can start the session from ${formattedDate} ${formattedStartTime}`
									);
									flag = true;
								}
								if (
									mockTest?.endTime != null &&
									new Date(mockTest?.endTime) < new Date()
								) {
									alert(`Test is over`);
									flag = true;
								}
								console.log(flag);
								if (!flag) {
									navigate(`/SessionStart/${mockTest?._id}`);
								}
							}}
							btnText="Start"
							endIcon={<PlayArrowOutlinedIcon />}
							variant="contained"
							sx={{
								color: "white",
								borderRadius: "20px",
								width: "105px",
								height: { xs: "34px", lg: "36px" },
								fontSize: { xs: "8px", lg: "12px" },
								backgroundColor: "#1fd655",
								fontWeight: 600,
							}}
						/>
					)}
				</Grid>
			</Grid>
		</Box>
	);
};
