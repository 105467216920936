import { ArrowBackIos, FiberManualRecord, Refresh } from "@mui/icons-material";
import { Box, Grid, List, Typography, Button } from "@mui/material";
import { CustomButton } from "../../components/CustomButton";
import Header from "../Dashboard/Header";
import AccessAlarmsOutlinedIcon from "@mui/icons-material/AccessAlarmsOutlined";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import Webcam from "react-webcam";
export const TestStartLayout = ({
	webcamRef,
	webcamEnabled,
	mobile,
	pageData,
	startTest,
	navigateBack,
	studentName,
	logOutHandler,
	packageName,
	questionsLeft,
	numQuestions,
	totalDurationMin,
	testStatus,
	timeLeft,
	reviewSession,
}) => {
	const CameraEnabled = `Webcam Status: ${
		webcamEnabled ? "Enabled" : "Disabled (Enable to continue)"
	}`;

	return (
		<Grid container>
			<Grid item xs={12} mb={2}>
				<Header test={true} studentName={studentName} />
			</Grid>
			<Grid item>
				<Grid
					container
					spacing={2}
					sx={{
						pl: { xs: 4, sm: 4, md: 6, lg: 10 },
						pr: { xs: 4, sm: 4, md: 4, lg: 10 },
					}}
				>
					{!mobile && (
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={12} alignItems="center">
									<CustomButton
										btnText="BACK"
										onClick={navigateBack}
										variant="outlined"
										startIcon={<ArrowBackIos />}
										sx={{
											border: "1px solid rgba(106,112,124,1)",
											borderadius: "50px",
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					)}
					<Grid item xs={12}>
						<Grid
							container
							item
							xs={12}
							style={{
								backgroundColor: "#F7F8F9",
								borderadius: "20px",
								padding: "20px",
								marginBottom: "20px",
							}}
						>
							<Grid
								item
								xs={6}
								style={{ justifyContent: "flex-start", alignItems: "center" }}
							>
								<Typography variant="h4">
									<Box
										sx={{
											fontFamily: "Urbanist",
											fontWeight: "bold",
											fontSize: { lg: "36px", xs: "20px" },
											display: "flex",
										}}
										mb={1}
									>
										{pageData?.packageName}
									</Box>
								</Typography>
								{
									<Typography variant="subtitle2">
										<Box
											sx={{
												fontFamily: "Urbanist",
												fontWeight: "medium",
												fontSize: "20px",
												display: "flex",
												width: { xs: "200px", lg: "400px" },
											}}
											mb={1}
										>
											{packageName}
										</Box>
									</Typography>
								}
							</Grid>
							{pageData?.testStatus != null &&
								pageData.attemptedQuestions.length !==
									pageData.questions.length && (
									<Grid
										item
										xs={6}
										style={{
											display: "flex",
											justifyContent: "flex-end",
											alignItems: "center",
										}}
									>
										<Typography sx={{ fontSize: "20px", display: "flex" }}>
											<Box sx={{ fontSize: "50px", color: "green" }}>
												{pageData.attemptedQuestions != null
													? pageData.attemptedQuestions
													: 0}
											</Box>
											<Box
												sx={{
													mt: 1,
													fontFamily: "Urbanist",
													fontWeight: 600,
													fontSize: "28px",
												}}
											>
												/{pageData?.questions.length}
											</Box>
										</Typography>
									</Grid>
								)}
							<Grid container spacing={2} alignItems="center" mt={2}>
								<Grid item xs={12} sm={12} md={3} lg={1.5}>
									<Typography variant="Subtitle2" align="center">
										<Box
											sx={{
												borderadius: "10px 10px 0px 0px",
												backgroundColor: "white",
												display: "flex",
												p: 2,
												fontSize: "14px",
												fontFamily: "Urbanist",
												fontWeight: 600,
												alignItems: "center",
											}}
										>
											<QuizOutlinedIcon />
											Questions
										</Box>
									</Typography>
									<Typography variant="Subtitle2" align="center">
										<Box
											sx={{
												borderadius: "10px",
												backgroundColor: "#F9BB47",
												color: "white",
												p: 2,
											}}
										>
											{questionsLeft ?? 0} of {numQuestions} questions Left
										</Box>
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									sm={12}
									md={3}
									lg={1.5}
									sx={{ mt: { xs: 2, sm: 2, md: 0, lg: 0 } }}
								>
									<Typography variant="Subtitle2" align="center">
										<Box
											sx={{
												borderadius: "10px 10px 0px 0px",
												backgroundColor: "white",
												display: "flex",
												p: 2,
												fontSize: "14px",
												fontFamily: "Urbanist",
												fontWeight: 600,
												alignItems: "center",
											}}
										>
											<AccessAlarmsOutlinedIcon />
											Time Limit
										</Box>
									</Typography>
									<Typography variant="Subtitle2" align="center">
										<Box
											sx={{
												borderadius: "10px",
												backgroundColor: "#F9BB47",
												color: "white",
												p: 2,
											}}
										>
											{Math.floor(timeLeft / 60) ?? totalDurationMin * 60} min{" "}
											{Math.round(timeLeft % 60) ?? 0} sec of {totalDurationMin}{" "}
											minutes Left
										</Box>
									</Typography>
								</Grid>
								<Grid
									item
									xs={10}
									sm={8}
									md={6}
									lg={9}
									style={{
										display: "flex",
										justifyContent: "flex-end",
										alignItems: "center",
									}}
								>
									{testStatus === "review" && (
										<Button
											disabled={mobile ? false : !webcamEnabled}
											onClick={reviewSession}
											endIcon={<Refresh />}
											variant="contained"
											borderadius="5px"
											sx={{
												width: "170px",
												height: "54px",
												backgroundColor: "rgba(131, 139, 161, 1)",
												color: "white",
												fontFamily: "Urbanist",
												borderadius: "15px",
											}}
										>
											REVIEW
										</Button>
									)}
									{testStatus === "start" && (
										<Button
											disabled={mobile ? false : !webcamEnabled}
											onClick={() => startTest(pageData)}
											endIcon={<PlayArrowOutlinedIcon />}
											variant="contained"
											borderadius="5px"
											sx={{
												width: "170px",
												height: "54px",
												backgroundColor: "#53D064",
												color: "white",
												fontFamily: "Urbanist",
												borderadius: "15px",
											}}
										>
											START
										</Button>
									)}
									{testStatus === "resume" && (
										<Button
											disabled={mobile ? false : !webcamEnabled}
											onClick={() => startTest(pageData)}
											endIcon={<Refresh />}
											variant="contained"
											borderadius="5px"
											sx={{
												width: "170px",
												height: "54px",
												backgroundColor: "#F9BB47",
												color: "white",
												fontFamily: "Urbanist",
												borderadius: "15px",
											}}
										>
											RESUME
										</Button>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{mobile ? (
						<></>
					) : (
						<Grid item xs={12}>
							<Grid container alignItems="center">
								<Grid item>
									<Typography variant="h6" sx={{ fontFamily: "Urbanist" }}>
										{CameraEnabled}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					)}

					{mobile ? (
						<></>
					) : (
						<Grid item xs={12}>
							<Grid container alignItems="center">
								<Grid item>
									<Typography variant="h4" sx={{ fontFamily: "Urbanist" }}>
										Test instructions:
									</Typography>
									<List sx={{ ml: 1 }}>
										<Typography variant="body1" sx={{ fontFamily: "Urbanist" }}>
											<FiberManualRecord sx={{ fontSize: "8px" }} /> 1. Webcam &
											Microphone will stay open during the exam
										</Typography>
										<Typography variant="body1" sx={{ fontFamily: "Urbanist" }}>
											<FiberManualRecord sx={{ fontSize: "8px" }} /> 2. Audio /
											Video / Screen movements or inputs will be analysed by the
											system / AI
										</Typography>
										<Typography variant="body1" sx={{ fontFamily: "Urbanist" }}>
											<FiberManualRecord sx={{ fontSize: "8px" }} /> 3. During
											the 30-minutes exam session, participant should not switch
											one’s test window
										</Typography>
										<Typography variant="body1" sx={{ fontFamily: "Urbanist" }}>
											<FiberManualRecord sx={{ fontSize: "8px" }} /> 4. Student
											can skip a question and can attempt it later if required
										</Typography>
										<Typography variant="body1" sx={{ fontFamily: "Urbanist" }}>
											<FiberManualRecord sx={{ fontSize: "8px" }} /> 5. During
											the exam, student is expected to look only at the screen.
											One should not look here or there; or talk to anybody
										</Typography>
										<Typography variant="body1" sx={{ fontFamily: "Urbanist" }}>
											<FiberManualRecord sx={{ fontSize: "8px" }} /> 6. No other
											person is expected to talk or interact with the child
											during the exam
										</Typography>
										<Typography variant="body1" sx={{ fontFamily: "Urbanist" }}>
											<FiberManualRecord sx={{ fontSize: "8px" }} /> 7.
											Proctoring system will keep a track of all unusual
											activity and will document the same
										</Typography>
										<Typography variant="body1" sx={{ fontFamily: "Urbanist" }}>
											<FiberManualRecord sx={{ fontSize: "8px" }} /> 8. Here’s
											the link to our Privacy Policy:
											https://liveolympiad.org/privacy-policy
										</Typography>
									</List>
								</Grid>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};
