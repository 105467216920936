import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useSearchParams } from "react-router-dom";
import useAuthHelper from "../../hooks/useAuthHelper";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../stores";
import { Box, Typography } from "@mui/material";

const Redirect = () => {
	const [searchParams, _] = useSearchParams();
	const [error, setError] = useState("");
	const navigate = useNavigate();
	const auth = searchParams.get("auth");
	const { login } = useAuthHelper();
	const setCurrentUser = useStore((state) => state.setCurrentUser);
	useEffect(() => {
		if (auth === undefined || auth === null) {
			navigate("/");
		} else {
			const creds = auth.split("|");
			login({
				userName: `+${creds[0]}`,
				password: creds[1],
				loginForced: true,
				mobile: false,
			}).then((res) => {
				if (res?.data?.success === true) {
					setCurrentUser(res.data?.data);
					localStorage.setItem("auth", "true");
					navigate("/parent");
				} else {
					setError(res?.data?.message);
				}
			});
		}
	}, [auth]);
	return (
		<Box
			sx={{
				display: "flex",
				width: "100%",
				height: "100vh",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				gap: "10px",
			}}
		>
			{!error && (
				<div>
					<Typography variant="h5">Authorizing User</Typography>
					<CircularProgress />
				</div>
			)}
			{!!error && (
				<div>
					<Typography variant="h5">Failed to Authorize user</Typography>
					<Typography>{error}</Typography>
				</div>
			)}
		</Box>
	);
};

export default Redirect;
