import { Grid } from "@mui/material";

import { UserDetails } from "./UserDetails";
import { UserProfileForm } from "./UserProfileForm";

export const ProfileLayout = ({ profile, file, setFile, upload }) => {
	return (
		<>
			<Grid container p={4} spacing={3}>
				<Grid item xs={12} sm={12} md={4} lg={4}>
					<UserDetails profile={profile} upload={upload} />
				</Grid>
				<Grid item xs={12} sm={12} md={8} lg={8}>
					<UserProfileForm profile={profile} />
				</Grid>
			</Grid>
		</>
	);
};
