import { ArrowBackIos, FiberManualRecord, Refresh } from "@mui/icons-material";
import { Box, Grid, List, Typography, Button, Stack } from "@mui/material";
import { CustomButton } from "../../components/CustomButton";
import Header from "../Dashboard/Header";
import AccessAlarmsOutlinedIcon from "@mui/icons-material/AccessAlarmsOutlined";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import Webcam from "react-webcam";
export const PracticeStartLayout = ({
  mobile,
  pageData,
  startTest,
  navigateBack,
  studentName,
  packageName,
  testStatus,
  reviewSession,
}) => {
  return (
    <Grid container>
      <Grid item xs={12} mb={2}>
        <Header test={true} studentName={studentName} />
      </Grid>
      <Grid
        item
        lg={12}
        xs={12}
        md={12}
        sm={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            pl: { xs: 4, sm: 4, md: 6, lg: 10 },
            pr: { xs: 4, sm: 4, md: 4, lg: 10 },
          }}
        >
          {!mobile && (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} alignItems="center">
                  <CustomButton
                    btnText="BACK"
                    onClick={navigateBack}
                    variant="outlined"
                    startIcon={<ArrowBackIos />}
                    sx={{
                      border: "1px solid rgba(106,112,124,1)",
                      borderadius: "50px",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={12}
            sm={12}
            lg={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                backgroundColor: "#F7F8F9",
                borderadius: "20px",
                padding: "20px",
                marginBottom: "20px",
                marginTop: "14%",
                height:"60%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack
                direction="column"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4" sx={{
                      fontFamily: "Urbanist",
                      fontWeight: "bold",
                      fontSize: { lg: "36px", xs: "20px" },
                    }}
                    mb={1}>
                  <Box
                    
                  >
                    {pageData?.packageName}
                  </Box>
                </Typography>
                {
                  <Typography variant="subtitle5" ml="46%"  >
                    <Box
                      sx={{
                        fontFamily: "Urbanist",
                        fontWeight: "medium",
                        fontSize: "20px",
                        width: "400px",
                      
                      }}
                      mb={1}
                    >
                      {packageName}
                    </Box>
                  </Typography>
                }
                {testStatus === "review" && (
                  <Button
                    onClick={reviewSession}
                    endIcon={<Refresh />}
                    variant="contained"
                    borderadius="5px"
                    sx={{
                      width: "170px",
                      height: "54px",
                      backgroundColor: "rgba(131, 139, 161, 1)",
                      color: "white",
                      fontFamily: "Urbanist",
                      borderadius: "15px",
                    }}
                  >
                    REVIEW
                  </Button>
                )}
                {testStatus === "start" && (
                  <Button
                    onClick={() => startTest(pageData)}
                    endIcon={<PlayArrowOutlinedIcon />}
                    variant="contained"
                    borderadius="5px"
                    sx={{
                      width: "170px",
                      height: "54px",
                      backgroundColor: "#53D064",
                      color: "white",
                      fontFamily: "Urbanist",
                      borderadius: "15px",
                    }}
                  >
                    START
                  </Button>
                )}
                {testStatus === "resume" && (
                  <Button
                    onClick={() => startTest(pageData)}
                    endIcon={<Refresh />}
                    variant="contained"
                    borderadius="5px"
                    sx={{
                      width: "170px",
                      height: "54px",
                      backgroundColor: "#F9BB47",
                      color: "white",
                      fontFamily: "Urbanist",
                      borderadius: "15px",
                    }}
                  >
                    RESUME
                  </Button>
                )}
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item>
                {/* <Typography variant='h4' sx={{ fontFamily: 'Urbanist' }}>Test instructions:</Typography>
                            <List sx={{ ml:1 }}>
                            <Typography variant='body1' sx={{ fontFamily: 'Urbanist' }}><FiberManualRecord sx={{ fontSize: '8px' }} /> 1. Webcam & Microphone will stay open during the exam</Typography>
                            <Typography variant='body1' sx={{ fontFamily: 'Urbanist' }}><FiberManualRecord sx={{ fontSize: '8px' }} /> 2. Audio / Video / Screen movements or inputs will be analysed by the system / AI</Typography>
                            <Typography variant='body1' sx={{ fontFamily: 'Urbanist' }}><FiberManualRecord sx={{ fontSize: '8px' }} /> 3. During the 30-minutes exam session, participant should not switch one’s test window</Typography>
                            <Typography variant='body1' sx={{ fontFamily: 'Urbanist' }}><FiberManualRecord sx={{ fontSize: '8px' }} /> 4. Student can skip a question and can attempt it later if required</Typography>
                            <Typography variant='body1' sx={{ fontFamily: 'Urbanist' }}><FiberManualRecord sx={{ fontSize: '8px' }} /> 5. During the exam, student is expected to look only at the screen. One should not look here or there; or talk to anybody</Typography>
                            <Typography variant='body1' sx={{ fontFamily: 'Urbanist' }}><FiberManualRecord sx={{ fontSize: '8px' }} /> 6. No other person is expected to talk or interact with the child during the exam</Typography>
                            <Typography variant='body1' sx={{ fontFamily: 'Urbanist' }}><FiberManualRecord sx={{ fontSize: '8px' }} /> 7. Proctoring system will keep a track of all unusual activity and will document the same</Typography>
                            <Typography variant='body1' sx={{ fontFamily: 'Urbanist' }}><FiberManualRecord sx={{ fontSize: '8px' }} /> 8. Here’s the link to our Privacy Policy: https://liveolympiad.org/privacy-policy</Typography>
                            </List> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
