import { environment } from "../environments/environment";
import axiosInstance from "../services/api.service";

export const usePractice = () => {
	async function createPracticeSession(packageId, body, token) {
		let endpoint = environment.activities + `/session/${packageId}`;

		let config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		return await axiosInstance().post(endpoint, body, config);
	}

	async function creatMockSession(packageId, body, token) {
		let endpoint = environment.activities + `/session/${packageId}`;

		let config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		return await axiosInstance().post(endpoint, body, config);
	}

	async function getRecommendation(sessionId, token) {
		let endpoint = environment.activities + `/recommend?sessionId=${sessionId}`;

		let config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		return await axiosInstance().get(endpoint, config);
	}

	async function getProgress(token, studentIndex) {
		let endpoint = environment.activities + `/progress/${studentIndex}`;

		let config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		return await axiosInstance().get(endpoint, config);
	}

	return {
		createPracticeSession,
		getRecommendation,
		getProgress,
		creatMockSession,
	};
};
