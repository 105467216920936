import React, { useState } from "react";
import { useEffect } from "react";
import { navigateAsPerSessionValidity } from "../../services/helpers";
import { usePayment } from "../../hooks/usePayment";
import { useQuery } from "react-query";
import { useStore } from "../../stores";
import { useParams } from "react-router-dom";
import { CustomSnackbar } from "../../components/CustomSnackbar";
import HomeLayout from "../../designs/Dashboard/HomeLayout";
import SubscriptionLayout from "../../designs/Dashboard/Subscription";

const Subscription = () => {
	const student = JSON.parse(window.localStorage.getItem("current_student"));
	const [paymentInfo, setPaymentInfo] = useState();
	const [subscriptionList, setSubscriptionList] = useState();
	const [subjectList, setSubjectList] = useState();
	const [subjectMode, setSubjectMode] = useState(0);
	const [subjects, setSubjects] = useState([]);
	const [snakeBarProps, setSnakeBarProps] = useState({});
	const params = useParams();
	const grade = params.grade;
	const {
		getUserPaymentInfo,
		getSubscriptions,
		getPaymentKey,
		createOrder,
		getSubjects,
		payOrder,
	} = usePayment();

	useEffect(() => {
		navigateAsPerSessionValidity(true);
	});

	const handleModeChange = (mode) => {
		if (mode !== subjectMode) {
			setSubjectMode(mode);
			if (mode === 3) {
				setSubjects(subjectList.map((subject) => subject.id));
			} else {
				setSubjects([]);
			}
		}
	};

	const handleSubjectSelect = (subjectId) => {
		if (subscriptionList?.subscribedCourses.includes(subjectId)) {
			return;
		}
		if (subjectMode !== 3 && subjects.includes(subjectId)) {
			setSubjects(subjects.filter((subject) => subject !== subjectId));
		} else {
			if (subjectMode === 1) {
				setSubjects([subjectId]);
			} else if (subjectMode === 2) {
				if (subjects.length < 2) {
					setSubjects([...subjects, subjectId]);
				} else {
					setSubjects([subjectId]);
				}
			}
		}
	};

	function loadRazorpay(payment) {
		if (payment == null) {
			if (subjectMode === 0) {
				setSnakeBarProps({
					snackbarFlag: true,
					msz: "Please select a Subscription plan",
					type: "warning",
				});
				return;
			}
			if (subjectMode === 2 && subjects.length !== 2) {
				setSnakeBarProps({
					snackbarFlag: true,
					msz: "Please select any two subject",
					type: "warning",
				});
				return;
			}
			if (subjectMode === 1 && subjects.length !== 1) {
				setSnakeBarProps({
					snackbarFlag: true,
					msz: "Please select a subject",
					type: "warning",
				});
				return;
			}
		}
		const script = document.createElement("script");
		script.src = "https://checkout.razorpay.com/v1/checkout.js";
		script.onerror = () => {
			alert("Razorpay SDK failed to load. Are you online?");
		};
		script.onload = async () => {
			try {
				let amount = 0,
					orderId = "",
					currency = "";

				if (payment == null) {
					let order = 0;
					if (subscriptionList?.subscribedCourses.length > 0) {
						order = subjects.length * 250;
					} else {
						order = subjects.length * 300 - (subjects.length - 1) * 50;
					}

					const result = await createOrder({
						amount: order * 100,
						courseIds: subjects,
						studentId: student?._id,
					});
					console.log(result.data);
					amount = result.data.data?.amount;
					orderId = result.data.data?.orderId;
					currency = result.data.data?.currency;
				} else {
					amount = payment?.amount * 100;
					orderId = payment?.orderId;
					currency = payment?.currency;
				}
				const { data } = await getPaymentKey();

				const options = {
					key: data.data,
					amount: amount.toString(),
					currency: currency,
					name: "LiveOlympiad",
					description: "LiveOlympiad Transaction",
					order_id: orderId,
					handler: async function (response) {
						const result = await payOrder({
							paymentId: response.razorpay_payment_id,
							orderId: response.razorpay_order_id,
						});
						if (result.status == 200) {
							refetch();
							subRefetch();
						}
					},
					prefill: {
						email: "info@liveolympiad.org",
						contact: student?.phoneNumber,
					},
					notes: {
						address: "example address",
					},
					theme: {
						color: "#03b2cb",
					},
				};
				const paymentObject = new window.Razorpay(options);
				paymentObject.open();
			} catch (err) {
				alert(err);
			}
		};
		document.body.appendChild(script);
	}

	const {
		data: PaymentData,
		isLoading: PaymentLoader,
		refetch,
	} = useQuery([`PaymentData`], () => getUserPaymentInfo(student?._id), {
		enabled: true,
		retry: false,
	});
	const { data: SubjectData, isLoading: SubjectLoader } = useQuery(
		[`SubjectData`],
		() => getSubjects(),
		{ enabled: true, retry: false }
	);
	const {
		data: SubscriptionData,
		isLoading: subscriptionsLoader,
		refetch: subRefetch,
	} = useQuery([`SubscriptionData`], () => getSubscriptions(student?._id), {
		enabled: true,
		retry: false,
	});

	useEffect(() => {
		setPaymentInfo(PaymentData?.data?.data);
		if (PaymentData?.data?.data?.length > 0) {
			localStorage.setItem("auth", "false");
		}
	}, [PaymentData]);

	useEffect(() => {
		setSubscriptionList(SubscriptionData?.data?.data);
	}, [SubscriptionData]);

	useEffect(() => {
		setSubjectList(SubjectData?.data?.data);
	}, [SubjectData]);
	const clearCurrentUser = useStore((state) => state.clearCurrentUser);
	let stuName = student?.fullName;
	const [fName, LName] = stuName.split(" ");
	return (
		<HomeLayout logOutHandler={clearCurrentUser}>
			<SubscriptionLayout
				logOutHandler={clearCurrentUser}
				stuName={fName}
				paymentInfo={paymentInfo}
				subscriptionList={subscriptionList}
				loadRazorpay={loadRazorpay}
				subjectList={subjectList}
				handleModeChange={handleModeChange}
				subjectMode={subjectMode}
				subjects={subjects}
				handleSubjectSelect={handleSubjectSelect}
				snakeBarProps={snakeBarProps}
				setSnakeBarProps={setSnakeBarProps}
			/>
		</HomeLayout>
	);
};
export default Subscription;
