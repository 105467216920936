import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { QuestionLayout } from '../../../designs/TestPlayer/components/QuestionLayout';
import React from "react"
import { usePractice } from '../../../hooks/usePractice';

const Question=({
        questions,
        createActivity,
        currentQuestion,
        setCurrentQuestion,
        setQuestions,
        getNewQuestion,
        packageId,
        questionStatusList,
        activityCounter,
        increaseActivityCounter,
        newQuestionLoading
    }) => {
    const [selected, setSelected] = useState(null);
    const [media, setMedia] = useState();
    const [correctOption, setCorrectOption] = useState(null);
    const [attempted, setAttempted] = useState(false);
    const [progress, setProgress] = useState(0);
    const params = useParams();
    const sessionId = params.sessionId;
    const review = params.review;

    const quizType = window.sessionStorage.getItem("QUIZ_TYPE");

    const [openDialog, setOpenDialog] = React.useState(false);

    const { getProgress } = usePractice();

    const handleAnswerDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleAnswerDialogClose = () => {
        setOpenDialog(false);
    };

    async function updateProgress() {
        return await getProgress(window.sessionStorage.getItem("USER_TOKEN"), window.sessionStorage.getItem("STUDENT_INDEX")).then((res) => {
            console.log("PROGESS DATA: ", res?.data);
            let mp = res?.data[JSON.parse(window.sessionStorage.getItem("SESSION_DETAILS:"+sessionId))?.subjects[0]]
            let subj = JSON.parse(window.sessionStorage.getItem("SESSION_DETAILS:"+sessionId))?.subjects[0];
            console.log("SUBJECT:", subj)
            if (mp?.correct) {
                // if (subj === "English") setProgress((mp?.correct)/(mp?.all));
                // else
                setProgress((mp?.tags[window.sessionStorage.getItem("DOMAIN")]?.correct)/(mp?.tags[window.sessionStorage.getItem("DOMAIN")]?.total));
            }
        }).catch((e) => {
            console.log(e);
        });
    }

    useEffect(() => {
        let questionsList = questions;
        questionsList?.forEach((q, index1) => {
            q['options']?.forEach((opt, index2) => {
                if (opt === q['answer']) {
                    questionsList[index1]['correctOption'] = index2;
                }
            })
        });
        setQuestions(questionsList);
        setCorrectOption(questionsList[currentQuestion]?.correctOption);
        window.sessionStorage.setItem("QUIZ_DETAILS:"+packageId, JSON.stringify(questionsList));
    }, [questions.length])
    
    useEffect(() => {
        console.log("PROGRESS:", Math.round(progress * 100));
    }, [progress])

    useEffect(() => {
        let questionsList = JSON.parse(window.sessionStorage.getItem("QUIZ_DETAILS:"+packageId))
        if (quizType !== "Practice" && quizType !== "Screening") {
            setSelected((questionStatusList && questionStatusList[currentQuestion]?.answerIndex !== -1) ? questionStatusList[currentQuestion]?.answerIndex : null);
            setCurrentQuestion(currentQuestion);
        } else {
            if (questionsList.length !== 0) {
                setSelected(questionsList[currentQuestion]['selectedOption'] ? questionsList[currentQuestion]['selectedOption'] : null);
                setCurrentQuestion(currentQuestion);
            }
        }   
    }, [questions, currentQuestion])

    const handleSubmit = (index) => {
        setCorrectOption(null);
        increaseActivityCounter();
        setSelected(index)
        let questionList = questions;
        questionList[currentQuestion]['selectedOption'] = index;
        questionList[currentQuestion]['status'] = 'attempted';
        setQuestions(questionList);
        window.sessionStorage.setItem("QUIZ_DETAILS:"+packageId, JSON.stringify(questionList));

        createActivity({
            sessionId: sessionId,
            type: "SUBMIT_ANSWER",
            questionId: questionList[currentQuestion]._id,
            answerIndex: index,
            questionIndex: currentQuestion
        }).then((res) => {
            if ((quizType === "Practice" || quizType === "Screening") && res?.data?.info?.answerIndex === index) updateProgress()
        })
    };

    useEffect(() => {
        if (questions?.length === 0) updateProgress();
    }, [questions])

    useEffect(() => {
        if (questions?.length === 0 || questions[questions?.length - 1].selectedOption + 1) {
            getNewQuestion();
        }
    }, [questions, selected])

    useEffect(() => {
        if (questions.length > 0) setMedia(questions[currentQuestion].media)
    }, [questions, currentQuestion])

    return (
        <QuestionLayout
            practiceMode={quizType === "Practice" || quizType === "Screening"}
            progress={progress}
            currentQuestion={currentQuestion}
            questions={questions}
            media={media}
            review={review}
            handleSubmit={handleSubmit}
            selected={selected}
            practiceAttempt={questions[currentQuestion]?.status === 'attempted'}
            correctOption={correctOption}
            openDialog={openDialog}
            handleAnswerDialogOpen={handleAnswerDialogOpen}
            handleAnswerDialogClose={handleAnswerDialogClose}
        />
    )
}

export default Question