import { Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, LinearProgress, List, ListItem, ListItemText, Modal, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import ReactAudioPlayer from "react-audio-player"

export const QuestionLayout = ({
    practiceMode,
    currentQuestion,
    questions,
    media,
    review,
    handleSubmit,
    selected,
    newQuestionLoading,
    practiceAttempt,
    correctOption,
    openDialog,
    handleAnswerDialogOpen,
    handleAnswerDialogClose,
    progress
}) => {
    

    return <Grid container p={2}>
        {practiceMode && <Box style={{flex: 1}}>
            <LinearProgress variant="determinate" value={progress ? Math.round(progress * 100) : 0} color='success' style={{height: 15, marginBottom: 5}} />
            <Typography
                textAlign={'center'}
                color="black"
                paddingBottom={1}
            >{progress ? Math.round(progress * 100) : 0}% Complete</Typography>
        </Box>}
        {<Grid item xs={12} sm={12} md={12} lg={12} >
            <Card sx={{ minHeight: "80px", mb: "1.5rem", padding: "1rem", border: 0.1}}>
                <span>
                    Question:{currentQuestion + 1}.{questions ? <div dangerouslySetInnerHTML={{ __html: questions[currentQuestion]?.question }} /> : <></>}
                </span>
                {media && <ReactAudioPlayer
                    src={media}
                    controls
                    style={{
                        width: "100%"
                    }}
                />}
            </Card>
        </Grid>}
        
        <Grid item xs={12} md={12} sm={12} lg={12} >
            <Card sx={{ mb: "1.5rem" }}>
                <List>
                    <ListItem>
                            <Grid container width="100%">
                            <Stack direction="column" spacing={2}>
                                {questions ? <Grid item xs={12} sm={12} md={12} lg={12}>
                                    {questions[currentQuestion]?.options?.map((option, index) =>
                                        <Button
                                            disabled={review === 'review=true'}
                                            key={index}
                                            variant="filled"
                                            onClick={() => {
                                                if (practiceMode && practiceAttempt) {
                                                    console.log("")
                                                } else {
                                                    handleSubmit(index);
                                                }
                                            }}
                                            sx={{
                                                textTransform:'none',
                                                width: "100%",
                                                textAlign: "left",
                                                border: "3px solid grey",
                                                borderColor: ((questions[currentQuestion]?.correctOption === index && practiceAttempt))
                                                ? "green"
                                                : (questions[currentQuestion]?.selectedOption === index)
                                                    ? "#4545A5"
                                                    : "lightgrey",
                                                mb: 1
                                            }}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Box sx={{ mr: "20px" }}>
                                                        <div dangerouslySetInnerHTML={{ __html: option }} />
                                                    </Box>
                                                }
                                            />

                                            {
                                                practiceAttempt && (questions[currentQuestion]?.correctOption === index) 
                                                ? (questions[currentQuestion]?.selectedOption === index) 
                                                    ? <Typography style={{fontWeight: 1000}}>Correct!</Typography>
                                                    : <Typography style={{fontWeight: 1000}}>Correct answer</Typography>
                                                : (questions[currentQuestion]?.selectedOption === index) 
                                                    ? <Typography style={{fontWeight: 1000}}>Your choice</Typography>
                                                    : <Typography></Typography>
                                            }
                                            {<Dialog
                                                slotProps={{backdrop: {style: {backgroundColor: 'transparent'}}}}
                                                // BackdropProps={{style: {backgroundColor: 'transparent'}}}    
                                                open={openDialog}
                                                onClose={handleAnswerDialogClose}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogTitle id="alert-dialog-title">
                                                {"Justification"}
                                                </DialogTitle>
                                                <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    {questions[currentQuestion]?.justification}
                                                </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                <Button onClick={handleAnswerDialogClose}>Continue</Button>
                                                </DialogActions>
                                            </Dialog>}
                                        </Button>
                                        )
                                    }
                                </Grid> : <Grid/>}
                            </Stack>
                        </Grid>
                    </ListItem>
                </List>
            </Card>
        </Grid>
        {practiceMode && practiceAttempt && <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button
                disabled={review === 'review=true'}
                variant="filled"
                onClick={() => {
                    handleAnswerDialogOpen();
                }}
                sx={{
                    textTransform:'none',
                    width: "100%",
                    textAlign: "left",
                    border: "3px solid grey",
                
                    mb: 2
                }}
            >
                <Typography>Click for justification</Typography>
            </Button>
        </Grid>}
    </Grid>
}