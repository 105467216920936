import React from "react";
import { Grid } from "@mui/material";
import { useQuery } from "react-query";
import useTests from "../../hooks/useTests";
import PropTypes from "prop-types";
import { ImageList, ImageListItem } from "@mui/material";

const Images = ({ studentId, packageId }) => {
	const { getShanpshots } = useTests();
	const { data: images } = useQuery(["images", studentId, packageId], () =>
		getShanpshots(studentId, packageId)
	);

	return (
		<Grid
			container
			sx={{
				height: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				marginTop: "20px",
				width: "100%",
			}}
		>
			<ImageList sx={{ width: "90%", height: 450 }} cols={3} rowHeight={164}>
				{images?.data?.data?.map((item) => (
					<ImageListItem key={item.img}>
						<img
							srcSet={`${item.fileUrl}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
							src={`${item.fileUrl}?w=164&h=164&fit=crop&auto=format`}
							alt={item.title}
							loading="lazy"
						/>
					</ImageListItem>
				))}
			</ImageList>
		</Grid>
	);
};

Images.propTypes = {
	studentId: PropTypes.string.isRequired,
	packageId: PropTypes.string.isRequired,
};

export default Images;
