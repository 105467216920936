export const buttonStyle = {
    MuiButtonBase: {
        styleOverrides: {
            root: {
                borderadius: "50px",
                padding: "9px 16px",
                '&.minWidth240': {
                    minWidth: "240px !important",
                    fontWeight:700,
                    fontSize:"20px",
                    fontFamily:'Urbanist',
                    textTransform:'none',
                    display:'flex'
                }
            }
        }
    },
}