export const environment = {
	env: "live",
	production: true,
	users: "https://users.liveolympiad.app",
	domain: "liveolympiad.org",
	cookieDomain: ".liveolympiad.org",
	testLiveolympiad: "https://packages.liveolympiad.app",
	sessionsUrl: "https://sessions.digishaala.com",
	regAppUrl: "https://app.liveolympiad.app",
	students: "https://students.liveolympiad.app",
	activities: "https://activity.liveolympiad.app",
	tabAppUrl: "https://ilp.liveolympiad.app",
	proctoring: "https://proctoring.liveolympiad.app",
	schools: "https://schools.liveolympiad.app",
	packages: "https://packages.liveolympiad.app",
	paymentUrl: "https://payments.liveolympiad.app",
};
