import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TestLayout from "../../designs/TestPlayer/TestLayout";
import { useActivity } from "../../hooks/useActivity";
import { Typography } from "@mui/material";
import { usePractice } from "../../hooks/usePractice";
import { environment } from "../../environments/environment";

export const TestPlayer = ({ mobile }) => {
	const navigate = useNavigate();

	const params = useParams();
	let packageId = params.packageId;
	let sessionId = params.sessionId;
	let review = params.review;

	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [packageName, setPackageName] = useState("LiveOlympiad Test");
	const [alreadySubmittedCurQues, setAlreadySubmittedCurQues] = useState(null);
	const [currentSession, setCurrentSession] = useState([]);
	const [questions, setQuestions] = useState([]);
	const [activityCounter, setActivityCounter] = useState(0);
	const [testType, setTestType] = useState("n/a");
	const [newQFlag, setNewQFlag] = useState(true);
	const [recommendation, setRecommendation] = useState({});
	const [newQuestionLoading, setNewQuestionLoading] = useState(false);
	const [token, setToken] = useState("");
	const [studIndex, setStudIndex] = useState(0);

	const [testStatus, setTestStatus] = useState("IDLE_STATE_START");
	const [returnPage, setReturnPage] = useState(
		`/SessionStart/${packageId}/` + mobile ? "/mobile" : ""
	);

	const [statusList, setStatusList] = useState([]);

	const { createActivity } = useActivity();

	const { getRecommendation } = usePractice();

	const increaseActivityCounter = () => {
		setActivityCounter(activityCounter + 1);
	};

	const triggerNewQuestion = () => {
		setNewQFlag(true);
	};

	useEffect(() => {
		setToken(window.sessionStorage.getItem("USER_TOKEN"));
		setStudIndex(window.sessionStorage.getItem("STUDENT_INDEX"));
	});

	useEffect(() => {
		if (testType == "Practice" || testType == "Screening") {
			let sessionId = window.sessionStorage.getItem("SESSION_ID");
			if (sessionId && newQFlag) {
				const recommendQuestion = async () => {
					setNewQuestionLoading(true);
					return await getRecommendation(
						sessionId,
						window.sessionStorage.getItem("USER_TOKEN")
					);
				};

				recommendQuestion().then((res) => {
					let que = res?.data?.data?.item;
					que["media"] = res?.data?.data?.item?.mediaUrl;
					console.log(res);
					if (res?.data?.status) {
						if (que) {
							console.log(res?.data?.status);
							setNewQuestionLoading(false);
							setQuestions([...questions, que]);
							console.log("QUESTION RECOMMENDED!");
							window.sessionStorage.setItem(
								"QUIZ_DETAILS:" + packageId,
								JSON.stringify([...questions, que])
							);
						} else {
							let token = window.sessionStorage.getItem("USER_TOKEN");
							console.log("No questions to load");
							alert("You reached the end of the quiz");
							window.open(
								`${environment.tabAppUrl}/SessionStart/${packageId}/`,
								"_self"
							);
						}
					} else {
						let token = window.sessionStorage.getItem("USER_TOKEN");
						console.log("No questions to load");
						alert("You reached the end of the quiz");
						window.open(
							`${environment.tabAppUrl}/SessionStart/${packageId}/`,
							"_self"
						);
					}
				});
			}
			setNewQFlag(false);
		}
	}, [newQFlag, testType]);

	useEffect(() => {}, [activityCounter]);

	const finishTest = () => {
		if (review === "review=true") {
			navigate("/report");
			return;
		}
		createActivity({
			sessionId: sessionId,
			type: testStatus,
		});
		window.sessionStorage.clear();
		navigate(returnPage);
	};

	const timeOver = () => {
		if (review === "review=true") {
			return;
		}
		createActivity({
			sessionId: sessionId,
			type: "TIME_OVER",
		});
		window.sessionStorage.clear();
		if (mobile) navigate(`/SessionStart/${packageId}/mobile`);
		else navigate("/dashboard");
	};

	useEffect(() => {
		setQuestions(
			JSON.parse(window.sessionStorage.getItem("QUIZ_DETAILS:" + packageId))
		);
		setPackageName(window.sessionStorage.getItem("QUIZ_NAME:" + packageId));
		setCurrentSession(
			JSON.parse(window.sessionStorage.getItem("SESSION_DETAILS:" + sessionId))
		);
		setTestType(window.sessionStorage.getItem("QUIZ_TYPE"));
	}, [packageId, sessionId]);

	useEffect(() => {
		if (questions.length) {
			let questionList = questions;
			if (currentSession.questions[currentQuestion]?.answerIndex > -1) {
				questionList[currentQuestion]["status"] = "attempted";
				questionList[currentQuestion]["selectedOption"] =
					currentSession.questions[currentQuestion]?.answerIndex;
			} else {
				questionList[currentQuestion]["status"] = "skipped";
			}
			window.sessionStorage.setItem(
				"QUIZ_DETAILS:" + packageId,
				JSON.stringify(questionList)
			);
		}
	}, [packageId, questions, currentQuestion]);

	useEffect(() => {
		if (
			currentSession?._id &&
			currentSession?.series[0] !== "Practice" &&
			currentSession?.series[0] !== "Screening"
		) {
			let pending = 0,
				skipped = 0,
				attempted = 0,
				visited = 0;
			if (questions.length)
				questions.forEach((question, index) => {
					question["status"] === "attempted" ||
					currentSession.questions[index]?.answerIndex > -1
						? attempted++
						: review === "review=true"
						? skipped++
						: question["status"] === "skipped"
						? index === currentQuestion
							? visited++
							: skipped++
						: pending++;
				});
			setStatusList([pending, skipped, attempted, visited]);
		}
	}, [questions, currentQuestion, currentSession, activityCounter]);

	useEffect(() => {
		if (review === "review=true") {
			return;
		}
		if (questions && (statusList[1] > 0 || statusList[2] > 0))
			createActivity({
				sessionId: sessionId,
				type: "NAVIGATE_QUESTION",
				questionId: questions[currentQuestion].questionId,
				questionIndex: currentQuestion,
			});
	}, [currentQuestion]);

	useEffect(() => {
		if (questions.length === 1)
			createActivity({
				sessionId: sessionId,
				type: "START_SESSION",
			});
	}, [questions]);

	useEffect(() => {
		window.onpopstate = () => {
			createActivity({
				sessionId: sessionId,
				type: "BROWSER_BACK",
			});
			window.sessionStorage.clear();
			navigate("/dashboard");
		};
	});

	useEffect(() => {
		if (statusList[0] === 0) {
			setTestStatus("FINISH_SESSION");
			if (mobile) setReturnPage(`/SessionStart/${packageId}/mobile`);
			else setReturnPage("/dashboard");
		} else {
			if (mobile) setReturnPage(`/SessionStart/${packageId}/mobile`);
			else setReturnPage(`/SessionStart/${packageId}/`);
		}
	}, [statusList]);

	return testType === "n/a" ? (
		<>
			<Typography
				style={{
					fontFamily: "Urbanist",
					fontWeight: 700,
					fontSize: "20px",
					/* identical to box height, or 100% */
					textAlign: "center",
					color: "#1C1B1F",
					marginBottom: 2,
				}}
			>
				LOADING....
			</Typography>
		</>
	) : (
		<TestLayout
			mobile={mobile}
			testType={testType}
			packageId={packageId}
			createActivity={createActivity}
			currentSession={currentSession}
			currentQuestion={currentQuestion}
			setCurrentQuestion={setCurrentQuestion}
			alreadySubmittedCurQues={alreadySubmittedCurQues}
			setAlreadySubmittedCurQues={setAlreadySubmittedCurQues}
			questions={questions}
			setQuestions={setQuestions}
			statusList={statusList}
			getNewQuestion={triggerNewQuestion}
			packageName={packageName}
			headerBtnText={review === "review==true" ? "Exit" : "Finish"}
			finish={finishTest}
			finishCheck={statusList[0] === 0}
			timeOver={timeOver}
			token={token}
			review={review}
			studIndex={studIndex}
			activityCounter={activityCounter}
			increaseActivityCounter={increaseActivityCounter}
			newQuestionLoading={newQuestionLoading}
		/>
	);
};
