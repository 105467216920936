import { Box, Grid, Typography } from "@mui/material";
import "./card.css";
import { useNavigate } from "react-router-dom";
import React, { useMemo } from "react";
const Card1 = ({ sessions, school, olympiad }) => {
	const navigate = useNavigate();

	const formattedDate = useMemo(() => {
		const options = { year: "numeric", month: "short", day: "numeric" };
		if (olympiad?.startTime) {
			return new Date(olympiad?.startTime.split("T")[0]).toLocaleDateString(
				"en-US",
				options
			);
		}
	}, [olympiad?.startTime]);

	const formattedStartTime = useMemo(() => {
		if (olympiad?.startTime) {
			const date = new Date(olympiad?.startTime);
			const localDate = date.toLocaleString();
			let time = localDate.split(" ")[1];
			return time;
		}
	}, [olympiad?.startTime]);

	const formattedEndTime = useMemo(() => {
		if (olympiad?.endTime) {
			const date = new Date(olympiad?.endTime);
			const localDate = date.toLocaleString();
			let time = localDate.split(" ")[1];
			return time;
		}
	}, [olympiad?.endTime]);

	const session = useMemo(() => {
		let attemptedSession = null;
		if (sessions) {
			sessions?.map((session) => {
				if (session?.packageId === olympiad?._id) {
					attemptedSession = session;
				}
			});
		}
		return attemptedSession;
	}, [sessions, olympiad]);

	const startTest = () => {
		let flag = false;
		sessionStorage.setItem("session", JSON.stringify(session));
		if (
			olympiad?.startTime != null &&
			new Date(olympiad?.startTime) > new Date()
		) {
			alert(
				`You can start the session from ${formattedDate} ${formattedStartTime}`
			);
			flag = true;
		}
		if (olympiad?.endTime != null && new Date(olympiad?.endTime) < new Date()) {
			alert(`Test is over`);
			flag = true;
		}
		if (!flag) {
			navigate("/SessionStart/" + olympiad?._id);
		}
	};

	return (
		<Box
			sx={{
				flexGrow: 1,
				marginLeft: { xs: 0, sm: "10px" },
				width: "230px",
			}}
		>
			<Grid container spacing={1}>
				<Grid
					item
					xs={12}
					sm={12}
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<div className="card">
						<div className="card-body">
							<div className="left-section">
								<Typography
									sx={{
										fontFamily: "Poppins",
										fontStyle: "normal",
										fontWeight: "700",
										fontSize: { xs: "10px", sm: "12px" },
										lineHeight: "16px",
										color: "#FFFFFF",
									}}
								>
									{olympiad?.packageName}
								</Typography>
								<Typography
									sx={{
										fontFamily: "Poppins",
										fontStyle: "normal",
										fontWeight: "400",
										fontSize: { xs: "8px", sm: "9px" },
										lineHeight: "12px",
										color: "#FFFFFF",
									}}
								></Typography>
							</div>
							<div className="right-section">
								<Typography
									className="date"
									sx={{
										fontFamily: "Poppins",
										fontSize: { xs: "18px", sm: "21px" },
										fontWeight: "400",
										fontStyle: "normal",
										lineHeight: "25px",
									}}
								>
									{formattedDate
										.split(" ")[1]
										.substring(0, formattedDate.split(" ")[1].length - 1)}
								</Typography>
								<Typography
									className="month"
									sx={{
										fontFamily: "Poppins",
										fontSize: { xs: "8px", sm: "10px" },
										fontWeight: "400",
										fontStyle: "normal",
										lineHeight: "12px",
									}}
								>
									{formattedDate.split(" ")[0]}
								</Typography>
							</div>
						</div>
						<div className="card-footer">
							<div className="bottom-section">
								<img src="images/maths.png" alt="maths" />
							</div>
							{!session ? (
								<div
									className="bottom-right-section"
									onClick={() => {
										startTest();
									}}
								>
									<Typography
										sx={{
											color: "#FFFFFF",
											fontSize: { xs: "12px", sm: "16px" },
											fontFamily: "Poppins",
											fontStyle: "normal",
											fontWeight: "400",
											lineHeight: "12px",
										}}
									>
										Enter
									</Typography>
									<img
										src="images/Arrow5.png"
										alt="Arrow"
										sx={{
											color: "#FFFFFF",
											fontSize: { xs: "24px", sm: "42px" },
										}}
									/>
								</div>
							) : (
								<div
									className="bottom-right-section"
									onClick={() => {
										sessionStorage.setItem("session", JSON.stringify(session));
										sessionStorage.setItem("package", JSON.stringify(olympiad));
										navigate(`/report`);
									}}
								>
									<Typography
										sx={{
											color: "#FFFFFF",
											fontSize: { xs: "12px", sm: "16px" },
											fontFamily: "Poppins",
											fontStyle: "normal",
											fontWeight: "700",
											lineHeight: "12px",
										}}
									>
										Report
									</Typography>
									<img
										src="images/Arrow5.png"
										alt="Arrow"
										sx={{
											color: "#FFFFFF",
											fontSize: { xs: "24px", sm: "42px" },
										}}
									/>
								</div>
							)}
						</div>
					</div>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Card1;
