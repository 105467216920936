import React, { useEffect, useMemo } from "react";
import HomeLayout from "../../designs/Dashboard/HomeLayout";
import { useState } from "react";
import { useQuery } from "react-query";
import { DashboardLayout } from "../../designs/Dashboard/DashboardLayout";
import { navigateAsPerSessionValidity, sort } from "../../services/helpers";
import useTests from "../../hooks/useTests";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../stores";
import { usePayment } from "../../hooks/usePayment";
const Dashboard = () => {
	useEffect(() => {
		navigateAsPerSessionValidity(true);
	});
	const navigate = useNavigate();

	const { getPackageList, getSessions } = useTests();
	const { getSubscriptions, getSubjects } = usePayment();
	const [subscribedPackages, setSubscribedPackages] = useState();
	const student = JSON.parse(window.localStorage.getItem("current_student"));
	let school = window.sessionStorage.getItem("school");
	let curentUser = useStore((state) => state.currentUser);
	const [subject, setSubject] = useState("Math");
	const [subjects, setSubjects] = useState();
	const { data: SubscriptionData } = useQuery(
		[`SubscriptionData`],
		() => getSubscriptions(student?._id),
		{ enabled: !!student?._id, retry: false }
	);
	const { data: CoursesData } = useQuery(["CoursesData"], () => getSubjects(), {
		enabled: true,
		retry: false,
	});

	useEffect(() => {
		if (CoursesData !== undefined) {
			let subs = {};
			CoursesData.data.data.forEach((course) => {
				subs[course.id] = course.name;
			});
			if (SubscriptionData?.data?.data?.subscribedCourses?.length !== 0) {
				setSubjects(
					SubscriptionData?.data?.data.subscribedCourses.map((item) =>
						subs[item].slice(0, 4)
					)
				);
			}
		}
	}, [SubscriptionData, CoursesData]);

	const { data: olympiads } = useQuery(
		[`Olumpiads`, student?.grade],
		() =>
			getPackageList({
				grade: student?.grade,
				series: "L-",
			}),
		{ enabled: !!student?.grade && !!curentUser?.id, retry: false }
	);

	const { data: SubjectData, isLoading: SubjectLoader } = useQuery(
		[`SubjectData`],
		() => getSubjects(),
		{ enabled: true, retry: false }
	);

	const { data: mocks } = useQuery(
		[`MockTest`, student?.grade],
		() =>
			getPackageList({
				grade: student?.grade,
				series: "Mock",
			}),
		{ enabled: !!student?.grade && !!curentUser?.id, retry: false }
	);

	const isNew = useMemo(() => {
		return new Date(student?.createdAt) > new Date("2023-12-14");
	}, [student]);
	const { data: sessions } = useQuery([`Sessions`, student?.studIndex], () =>
		getSessions(student?.studIndex)
	);

	useEffect(() => {
		let packageList = olympiads?.data.map((data) => {
			return data;
		});
		setSubscribedPackages(
			packageList?.filter((test) => {
				let flag = false;
				subjects?.forEach((subject) => {
					flag = flag || test.subject[0].search(subject) !== -1;
				});
				return flag;
			})
		);
	}, [subjects, olympiads]);

	const sessionStart = (sessionStartData) => {
		navigate(`/SessionStart/${curentUser?.access_token}/` + sessionStartData);
	};
	const clearCurrentUser = useStore((state) => state.clearCurrentUser);
	return (
		<HomeLayout logOutHandler={clearCurrentUser}>
			<DashboardLayout
				isNew={isNew}
				sessions={sessions?.data}
				olympiads={sort(olympiads?.data, (a, b) =>
					a?.series[0].localeCompare(b?.series[0])
				)}
				mocks={mocks?.data}
				subject={subject}
				setSubject={setSubject}
				subscriptionSubject={subjects}
				subjects={SubjectData?.data?.data}
				subscriptions={SubscriptionData?.data?.data?.subscribedCourses}
			/>
		</HomeLayout>
	);
};

export default Dashboard;
