export class Endpoints {
	static TESTS = "/tests";
	static SESSIONS = "/sessions";
	static PACKAGES = "/packages";
	static LOGIN_USER = "/auth/login";
	static LOGOUT_USER = "/auth/logout";
	static PAYMENT_INFO = "/payments";
	static SUBSCRIPTION_INFO = "/subscriptions";
	static COURSES = "/courses";
	static TEST_LIST = "/tests/all";
	static GET_EDUCATION_INFO = "/education-info";
	static GET_PERSONAL_INFO = "/personal-info";
	static ACTIVITY = "/activity";
	static SYLLABUS = "/syllabus";
	static ORDER = "/order";
	static PAYMENT_KEY = "/paymentkey";
	static CAPTURE = "/capture";
}
