import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../stores";
import { useStudent } from "../../hooks/useStudent";
import { useQuery } from "react-query";
import { useSchool } from "../../hooks/useSchool";
import { ParentLog } from "../../designs/Auth/ParentLog";
import { responsiveStype } from "../../beautifiers/responsive";
import { usePayment } from "../../hooks/usePayment";

const ParentView = ({ logOutHandler }) => {
	const navigate = useNavigate();
	const currentUser = useStore((state) => state.currentUser);
	const clearCurrentUser = useStore((state) => state.clearCurrentUser);
	const [children, setChildren] = useState([]);
	const { getUserStudents, profileDataDetails } = useStudent();
	const { getUserSubscriptions, getSubjects } = usePayment();
	const { getSchools } = useSchool();
	const [page, setPage] = useState(0);
	const PAGE_SIZE = 2;
	const startIndex = page * PAGE_SIZE;
	const endIndex = startIndex + PAGE_SIZE;
	const authroute = localStorage.getItem("auth");
	const { data: students } = useQuery(
		[`students`, currentUser],
		() => getUserStudents(currentUser?.id),
		{ enabled: !!currentUser?.id, retry: false }
	);

	const { data: SubjectData, isLoading: SubjectLoader } = useQuery(
		[`SubjectData`],
		() => getSubjects(),
		{ enabled: !!currentUser, retry: false }
	);

	const { data: userSubscriptions } = useQuery(
		[`user-subscriptions`, currentUser],
		() => getUserSubscriptions(currentUser?.id),
		{ enabled: !!currentUser?.id, retry: false }
	);

	const subscriptions = useMemo(() => {
		let subscriptions = {};
		if (userSubscriptions?.data?.data) {
			userSubscriptions?.data?.data?.forEach((element) => {
				subscriptions[element?.studentId] = element;
			});
		}
		return subscriptions;
	}, [userSubscriptions]);

	const numPages = Math.ceil(students?.data?.data?.length / PAGE_SIZE);

	const handleClickPrev = () => {
		if (page > 0) setPage((prevIndex) => Math.max(prevIndex - 1, 0));
	};

	const handleClickNext = () => {
		if (page + 1 < numPages)
			setPage((prevIndex) => Math.min(prevIndex + 1, numPages));
	};

	const { getSchoolById } = useSchool();

	useEffect(() => {
		if (students?.data?.data) {
			if (students?.data?.data?.length === 1) {
				localStorage.setItem("single", "true");
				const child = students?.data?.data[0];
				getSchoolById(child?.schoolId).then((resp) => {
					if (resp) {
						onHandleClickSubNavigate(
							students?.data?.data[0],
							0,
							resp.data?.data?.name
						);
					}
				});
				return;
			} else {
				localStorage.setItem("single", "false");
			}
			setChildren(students?.data?.data?.slice(startIndex, endIndex));
		}
	}, [students, startIndex, endIndex]);

	const onHandleClickCreate = async () => {
		let res = await profileDataDetails({
			userId: currentUser?.id,
		});
		if (res?.data?.success) {
			navigate("/schooldetails/" + res?.data?.data?._id);
		}
	};

	const onHandleClickNavigate = async (child, index, school) => {
		child["studIndex"] = index;
		child["school"] = school;
		localStorage.setItem("current_student", JSON.stringify(child));

		if (authroute === "true") {
			navigate("/subscription");
		} else {
			navigate("/dashboard");
		}
	};

	const onHandleClickSubNavigate = (child, index, school) => {
		child["studIndex"] = index;
		child["school"] = school;
		localStorage.setItem("current_student", JSON.stringify(child));
		if (authroute === "true") {
			navigate("/subscription");
		} else {
			navigate("/dashboard");
		}
	};

	const handleLogOut = (event) => {
		event.preventDefault(); // Prevent the default form submission behavior
		clearCurrentUser();
		localStorage.removeItem("auth");
		navigate("/");
	};
	return (
		<ParentLog
			handleClickNext={handleClickNext}
			handleClickPrev={handleClickPrev}
			responsiveStyle={responsiveStype}
			logOutHandler={handleLogOut}
			page={page}
			setPage={setPage}
			numPages={numPages}
			onHandleClickNavigate={onHandleClickNavigate}
			onHandleClickCreate={onHandleClickCreate}
			onHandleClickSubNavigate={onHandleClickSubNavigate}
			children={students?.data?.data?.slice(startIndex, endIndex)}
			subscriptions={subscriptions}
			subjectList={SubjectData?.data?.data}
		/>
	);
};

export default ParentView;
