import GoldCard from "../designs/Dashboard/GoldCard";
import HomeLayout from "../designs/Dashboard/HomeLayout";
import { ProfileLayout } from "../designs/Dashboard/ProfileLayout";
import IndividualReport from "../designs/IndividualReports/IndividualReport";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import { Login } from "../pages/Auth/Login";
import ParentView from "../pages/Auth/ParentView";
import Redirect from "../pages/Auth/Redirect";
import Booklet from "../pages/Dashboard/Booklet";
import Dashboard from "../pages/Dashboard/Dashboard";
import Profile from "../pages/Dashboard/Profile";
import { Subjects } from "../pages/Dashboard/Subjects";
import Subscription from "../pages/Dashboard/Subscription";
import SyllabusEnglish from "../pages/Dashboard/SyllabusEnglish";
import SyllabusMath from "../pages/Dashboard/SyllabusMath";
import SyllabusScience from "../pages/Dashboard/SyllabusScience";
import PersonalDetails from "../pages/Onboarding/PersonalDetails";
import SchoolDetails from "../pages/Onboarding/SchoolDetails";
import { SessionStart } from "../pages/Test/SessionStart";
import { TestPlayer } from "../pages/TestPlayer/TestPlayer";

export const routes = [
	{
		path: "/",
		element: <Login replace />,
	},
	{
		path: "/:index",
		element: <Login />,
	},
	{
		path: "/dashboard",
		element: <Dashboard />,
	},
	{
		path: "/subscription",
		element: <Subscription />,
	},
	{
		path: "/SessionStart/:packageId",
		element: <SessionStart mobile={false} />,
	},
	{
		path: "/SessionStart/:userToken/:packageId/:studIndex/mobile",
		element: <SessionStart mobile={true} />,
	},
	{
		path: "/TestPlayer/:packageId/:sessionId/:review",
		element: <TestPlayer mobile={false} />,
	},
	{
		path: "/TestPlayer/:packageId/:sessionId/:review/mobile",
		element: <TestPlayer mobile={true} />,
	},
	{
		path: "/individualReport",
		element: <IndividualReport />,
	},
	{
		path: "/parent",
		element: <ParentView />,
	},
	{
		path: "/profile",
		element: <Profile />,
	},
	{
		path: "/homelayout",
		element: <HomeLayout />,
	},
	{
		path: "/report",
		element: <GoldCard />,
	},
	{ path: "/booklet", element: <Booklet /> },
	{
		path: "/subjects",
		element: <Subjects />,
	},
	{
		path: "/syllabusMath",
		element: <SyllabusMath />,
	},
	{
		path: "/syllabusEnglish",
		element: <SyllabusEnglish />,
	},
	{
		path: "/syllabusScience",
		element: <SyllabusScience />,
	},
	{
		path: "/forgotpassword",
		element: <ForgotPassword />,
	},
	{
		path: "/redirect",
		element: <Redirect />,
	},
	{
		path: "/schoolDetails/:userId",
		element: <SchoolDetails />,
	},
	{
		path: "/personalDetails/:userId",
		element: <PersonalDetails />,
	},
];
