import { Endpoints } from "../environments/endpoints";
import { environment } from "../environments/environment";
import axiosInstance from "../services/api.service";

export const useActivity = () => {
	const createActivity = async (body) => {
		const endpoint = environment.activities;
		return await axiosInstance()
			.post(endpoint, body)
			.catch((e) => {});
	};

	return { createActivity };
};
