import React, { useEffect, useState } from "react";
import { getErrorMsz } from "../../services/validator";
import { useStudent } from "../../hooks/useStudent";
import { useSchool } from "../../hooks/useSchool";
import { useMutation, useQuery } from "react-query";
import { useStore } from "../../stores";
import { navigateAsPerSessionValidity } from "../../services/helpers";
import { useNavigate } from "react-router-dom";
import { ProfileLayout } from "../../designs/Dashboard/ProfileLayout";
import HomeLayout from "../../designs/Dashboard/HomeLayout";

const isValidEmail = (email) => {
	const validEmailRegex = new RegExp(
		"^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
	);
	return validEmailRegex.test(email);
};

const category = [
	{
		label: "F",
		value: 1,
	},
	{
		label: "M",
		value: 2,
	},
];

const Profile = (props) => {
	const [profile, setProfile] = useState(
		JSON.parse(localStorage.getItem("current_student"))
	);
	const currentUser = useStore((state) => state.currentUser);
	const { uploadPic, updateStudent } = useStudent();

	const upload = async (file) => {
		// Create a new FormData object and append the file and other fields
		const data = new FormData();
		data.append("file", file);
		data.append("purpose", "profile_pic");
		data.append("id", profile?._id);

		// Create a config object with the required headers and data
		const resp = await uploadPic(data);
		if (resp?.data?.success) {
			const studentResp = await updateStudent({
				_id: profile?._id,
				profileImageurl: resp?.data?.data,
				userId: currentUser?.id,
			});
			Object.assign(profile, studentResp?.data?.data);
			setProfile({ ...profile, ...studentResp?.data?.data });
			window.location.reload();
		}
	};

	return (
		<HomeLayout>
			<ProfileLayout profile={profile} upload={upload} />
		</HomeLayout>
	);
};

export default Profile;
