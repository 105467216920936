import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import Header from "./Header";
import { Typography, Box } from "@mui/material";
import { SubscriptionSubject } from "./SubscriptionSubject";
import { CustomSnackbar } from "../../components/CustomSnackbar";
import { CustomButton } from "../../components/CustomButton";
export default function SubscriptionLayout(props) {
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={10} lg={5} p={4}>
          <SubscriptionSubject {...props} />
        </Grid>

        <Grid item xs={12} sm={12} md={10} lg={4} mt={3} p={2}>
          {props.paymentInfo?.length > 0 && (
            <Typography
              variant="body1"
              fontSize="20px"
              fontWeight={600}
              ml={2}
              fontFamily="Urbanist"
            >
              Past order details{" "}
            </Typography>
          )}
          {props.paymentInfo?.map((item) => {
            return (
              <Grid
                container
                mt={2}
                style={{
                  backgroundColor: "#F7F8F9",
                  borderRadius: "20px",
                  padding: "20px",
                  marginBottom: "10px",
                }}
              >
                <Grid item xs={12}>
                  <Typography fontWeight="bold">
                    <Box sx={{ fontSize: "20px", fontFamily: "Urbanist" }}>
                      {item.courseIds.split(",").length} Subject
                      {item.courseIds.split(",").length > 1 ? "s" : ""}
                      Subscription{" "}
                      {item?.status != "captured" ? "(pending)" : ""}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={6} mt={1}>
                  <Typography>
                    <Box
                      sx={{
                        fontSize: "16px",
                        fontFamily: "Urbanist",
                        fontWeight: "600",
                        color: "#838BA1",
                      }}
                    >
                      Subjects : {item.courseIds}
                    </Box>
                  </Typography>
                  <Typography>
                    <Box
                      sx={{
                        fontSize: "16px",
                        fontFamily: "Urbanist",
                        fontWeight: "600",
                        color: "#838BA1",
                      }}
                    >
                      Amount: Rs.{item.amount ? item.amount : 0}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={6} mt={1}>
                  <Typography fontWeight="bold">
                    <Box
                      sx={{
                        fontSize: "16px",
                        fontFamily: "Urbanist",
                        fontWeight: "600",
                        color: "#838BA1",
                      }}
                    >
                      Date: {item.updatedAt && item.updatedAt.split("T")[0]}
                    </Box>
                  </Typography>
                  <Typography fontWeight="bold">
                    <Box
                      sx={{
                        fontSize: "16px",
                        fontFamily: "Urbanist",
                        fontWeight: "600",
                        color: "#838BA1",
                      }}
                    >
                      Mode: {item.paymentType ? item.paymentType : "NA"}
                    </Box>
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",
                  }}
                >
                  {item?.status != "captured" && (
                    <CustomButton
                      onClick={() => props.loadRazorpay(item)}
                      btnText="Retry"
                      color="primary"
                      variant="contained"
                      sx={{
                        mt: "5px",
                        marginLeft: "2px",
                        fontSize: "12px",
                        fontWeight: "200",
                        fontFamily: "Urbanist",
                        borderRadius: "50px",
                      }}
                    />
                  )}
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      {Object.keys(props.snakeBarProps).length > 0 && (
        <CustomSnackbar
          {...props.snakeBarProps}
          setSnakeBarProps={props.setSnakeBarProps}
        />
      )}
    </Box>
  );
}
