import { Endpoints } from "../environments/endpoints";
import { environment } from "../environments/environment";
import axiosInstance from "../services/api.service";

export const useStudent = () => {
	const getEducation = async () => {
		const endpoint = environment.users + Endpoints.GET_EDUCATION_INFO;
		return await axiosInstance().get(endpoint);
	};
	const getPersonalData = async () => {
		const endpoint = environment.users + Endpoints.GET_PERSONAL_INFO;
		return await axiosInstance().get(endpoint);
	};
	const getProfile = async () => {
		const endpoint = environment.users + "/profile";
		return await axiosInstance().get(endpoint);
	};

	const getUserStudents = async (userId) => {
		const endpoint = environment.students + `/${userId}?type=user`;
		return await axiosInstance().get(endpoint);
	};

	const uploadPic = async (data) => {
		const endpoint = environment.users + `/file/upload`;
		return await axiosInstance().post(endpoint, data);
	};

	const updateStudent = async (body) => {
		const endpoint = environment.students;
		return await axiosInstance().post(endpoint, body);
	};

	return {
		getEducation,
		getPersonalData,
		getProfile,
		getUserStudents,
		updateStudent,
		uploadPic,
	};
};
