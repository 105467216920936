import {
	Button,
	Card,
	Grid,
	List,
	ListItem,
	Stack,
	Typography,
} from "@mui/material";
import Question from "../../pages/TestPlayer/components/Question";
import Header from "../Dashboard/Header";
import Pallette from "./components/Pallette";
import TopAction from "./components/TopAction";
import { PracticeSidebar } from "../../pages/TestPlayer/components/PracticeSidebar";
import { ArrowBackIos } from "@mui/icons-material";
import { environment } from "../../environments/environment";

const TestLayout = ({
	mobile,
	review,
	questions,
	setQuestions,
	createActivity,
	currentQuestion,
	setCurrentQuestion,
	currentSession,
	packageId,
	statusList,
	packageName,
	headerBtnText,
	getNewQuestion,
	token,
	studIndex,
	finish,
	finishCheck,
	timeOver,
	activityCounter,
	increaseActivityCounter,
	newQuestionLoading,
	testType,
}) => {
	return (
		<Grid container>
			<Grid item xs={12} mb={2}>
				<Header
					test={true}
					callback={finish}
					finishCheck={finishCheck}
					btnText={headerBtnText}
				/>
			</Grid>
			{(testType === "Practice" || testType === "Screening") && (
				<Button
					onClick={() => {
						if (mobile) {
							window.open(
								`${environment.tabAppUrl}/SessionStart/${packageId}/mobile`,
								"_self"
							);
						} else {
							window.open(
								`${environment.tabAppUrl}/SessionStart/${packageId}/`,
								"_self"
							);
						}
					}}
					variant="outlined"
					startIcon={<ArrowBackIos />}
					sx={{ marginLeft: "17px", color: "#1C1B1F" }}
				>
					Continue Later
				</Button>
			)}
			<Grid item={true} xs={12} sm={12} md={12} lg={12}>
				<TopAction
					practice={testType === "Practice" || testType === "Screening"}
					createActivity={createActivity}
					packageName={packageName}
					questions={questions}
					currentQuestion={currentQuestion}
					setCurrentQuestion={setCurrentQuestion}
					newQuestionLoading={newQuestionLoading}
				/>
			</Grid>
			<Grid item={true} xs={12} sm={12} md={8} lg={9}>
				<Question
					createActivity={createActivity}
					packageId={packageId}
					setQuestions={setQuestions}
					questions={questions}
					getNewQuestion={getNewQuestion}
					currentQuestion={currentQuestion}
					setCurrentQuestion={setCurrentQuestion}
					questionStatusList={currentSession?.questions}
					activityCounter={activityCounter}
					increaseActivityCounter={increaseActivityCounter}
					newQuestionLoading={newQuestionLoading}
				/>
			</Grid>
			<Grid item={true} md={4} lg={3}>
				{testType === "Practice" || testType === "Screening" ? (
					<>
						<PracticeSidebar
							createActivity={createActivity}
							packageId={packageId}
							setQuestions={setQuestions}
							questions={questions}
							getNewQuestion={getNewQuestion}
							currentQuestion={currentQuestion}
							setCurrentQuestion={setCurrentQuestion}
							questionStatusList={currentSession?.questions}
							increaseActivityCounter={increaseActivityCounter}
							newQuestionLoading={newQuestionLoading}
						/>
					</>
				) : (
					<Pallette
						mobile={mobile}
						createActivity={createActivity}
						currentSession={currentSession}
						questions={questions}
						currentQuestion={currentQuestion}
						setCurrentQuestion={setCurrentQuestion}
						statusList={statusList}
						timeOver={timeOver}
						packageId={packageId}
						review={review}
					/>
				)}
			</Grid>
		</Grid>
	);
};

export default TestLayout;
