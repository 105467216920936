import Card1 from "../../components/card1";
import { Grid, Stack, Typography } from "@mui/material";

const Olympiads = ({
	olympiads,
	sessions,
	qualification,
	isNew,
	subscriptionSubject,
}) => {
	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography p={2}></Typography>
			</Grid>
			<Stack direction="row" sx={{ overflowX: "auto" }}>
				{olympiads?.map((olympiad) => (
					<div key={olympiad?._id}>
						{(isNew &&
							olympiad?.series[0] === "Mock" &&
							subscriptionSubject?.includes(
								olympiad?.subject[0].slice(0, 4)
							)) ||
						(!isNew &&
							(olympiad?.series[0] === "L-1" ||
								qualification[olympiad?.subject[0]] === true)) ? (
							<Grid item>
								<Card1 olympiad={olympiad} sessions={sessions} />
							</Grid>
						) : null}
					</div>
				))}
			</Stack>
		</Grid>
	);
};

export default Olympiads;
