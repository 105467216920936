import { Chip, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/material";
// import { environment } from "../../environments/environment";
import React, { useState } from "react";
export const SubjectSelector = ({ setPage, text, setSubject, subject }) => {
	const [color, setColor] = useState(
		subject && subject === "Science" ? true : false
	);
	const [colorBlue, setColorBlue] = useState(
		subject && subject === "Math" ? true : false
	);
	const [colorRed, setColorRed] = useState(
		subject && subject === "English" ? true : false
	);

	const downloadBooklet = (subject) => {
		const student = JSON.parse(localStorage.getItem("current_student"));
		const grade = student?.grade.replace("Class", "Grade");
		const pdfUrl = "https://s3.ap-south-1.amazonaws.com/edulabs-lo/booklets/LO-" + grade + "-" + subject + ".pdf";
		const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "LO-Grade-10-Math-Science.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
	};

	const toggleFunc = () => {
		setSubject("Science");
		setColor(true);
		setColorBlue(false);
		setColorRed(false);
	};
	const toggleMaths = () => {
		setSubject("Math");
		setColorBlue(true);
		setColor(false);
		setColorRed(false);
	};
	const toggleEnglish = () => {
		setSubject("English");
		setColorRed(true);
		setColor(false);
		setColorBlue(false);
	};
	return (
		<Grid mt={2}>
			<Grid item xs={12} sm={9} md={8} lg={9}>
				{setPage === "Booklet" ? <Typography>Booklets</Typography> : <Typography>Mock Tests</Typography>}
				<Stack direction="row" spacing={2} mt={1}>
					<Chip
						onClick={() => {
							if (setPage === "Booklet") {
								downloadBooklet("English")
							} else toggleEnglish();
						}}
						label="English"
						variant="contained"
						sx={{
							":hover": { bgcolor: "#EF4255", color: "white" },
							color: colorRed ? "white" : "#EF4255",
							border: "2px solid #EF4255",
							fontWeight: 600,
							fontSize: "12px",
							backgroundColor: colorRed ? "#EF4255" : "white",
						}}
					/>					
					<Chip
						onClick={() => {
							if (setPage === "Booklet") {
								downloadBooklet("Math")
							} else toggleMaths();
						}}
						label="Mathematics"
						variant="contained"
						sx={{
							":hover": { bgcolor: "#4545A5", color: "white" },
							color: colorBlue ? "white" : "#4545A5",
							border: "2px solid #4545A5",
							fontWeight: 600,
							fontSize: "12px",
							backgroundColor: colorBlue ? "#4545A5" : "white",
						}}
					/>
					{/* {environment.env != "school" && (
						<Chip
							label="All"
							onClick={() => (0)}
							variant="contained"
							sx={{
								":hover": { bgcolor: "#F9BB47", color: "white" },
								color: "#F9BB47",
								border: "2px solid #F9BB47",
								fontWeight: 600,
								fontSize: "12px",
								backgroundColor: "white",
							}}
						/>
					)} */}
					<Chip
						onClick={() => {
							if (setPage === "Booklet") {
								downloadBooklet("Science")
							} else toggleFunc();
						}}
						label="Science"
						variant="contained"
						sx={{
							":hover": { bgcolor: "#42BBEF", color: "white" },
							color: color ? "white" : "#42BBEF",
							border: "2px solid #42BBEF",
							fontWeight: 600,
							fontSize: "12px",
							backgroundColor: color ? "#42BBEF" : "white",
						}}
					/>
				</Stack>
			</Grid>
		</Grid>
	);
};
